import { useTranslation } from "react-i18next";

import Accordion from '../Reusable/Accordion';
import { UserData } from "../MeetingCall/Types";
import RobotPicture from '../../images/robot_picture.png';
import { logOut } from '../../utils/genericUtils';
import { LogOutIcon } from "../Icons";

import styled from 'styled-components';
import { Button } from "../../theme";



const Profile = styled(Accordion)`
  border: var(--standardBorder);
  border-radius: 16px;
  margin-top: auto;
  margin-bottom: 25px;

  .marker {
    transform: rotate(180deg);
  }

  &[open] {
    overflow: unset;
  }

  &[open] summary {
    &:hover {
      border: solid 1px transparent;
    }
  }

  &[open] .marker {
    transform: rotate(0deg);
  }

  summary {
    border-radius: 16px;
    font-weight: 700;
    &:hover {
      border: solid 1px transparent;
      text-decoration: underline;
    }
  }
`;

const Image = styled.img`
  width: 40px;
  border-radius: 50%;
`;


const Logout = styled(Button)`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0 12px 16px 38px;
  padding-left: 12px;
  font-weight: 500;
  text-decoration: none;
`;



type Props = Pick<UserData, "profile">;

const User = ({ profile }: Props) => {
  const { name, email, picture } = profile;
  const { t } = useTranslation();


  return (
    <Profile
      title={<>{name ?? email}</>}
      icon={
        <Image width="40"
          src={picture}
          alt='profile avatar'
          onError={({ currentTarget }) => (currentTarget.src = RobotPicture)}
        />
      }
    >
      <Logout link onClick={() => logOut(true)}>
        <LogOutIcon color="var(--brand-primary" />
        <span>{t('user-dropdown.log-out', 'Log Out')}</span>
      </Logout>
    </Profile>
  );
};

export default User;