import React from 'react';

const Transcript = (props: React.HTMLAttributes<HTMLOrSVGElement>) => (
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M17.0703 0.679688H2.92969C1.31424 0.679688 0 1.99393 0 3.60938V14.4248C0 16.0403 1.31424 17.3545 2.92969 17.3545H17.0703C18.6858 17.3545 20 16.0403 20 14.4248V3.60938C20 1.99393 18.6858 0.679688 17.0703 0.679688ZM18.8281 14.4248C18.8281 15.394 18.0396 16.1826 17.0703 16.1826H2.92969C1.96045 16.1826 1.17188 15.394 1.17188 14.4248V3.60938C1.17188 2.64014 1.96045 1.85156 2.92969 1.85156H17.0703C18.0396 1.85156 18.8281 2.64014 18.8281 3.60938V14.4248Z" fill="black" />
    <path d="M17.0703 0.679688H2.92969C1.31424 0.679688 0 1.99393 0 3.60938V14.4248C0 16.0403 1.31424 17.3545 2.92969 17.3545H17.0703C18.6858 17.3545 20 16.0403 20 14.4248V3.60938C20 1.99393 18.6858 0.679688 17.0703 0.679688ZM18.8281 14.4248C18.8281 15.394 18.0396 16.1826 17.0703 16.1826H2.92969C1.96045 16.1826 1.17188 15.394 1.17188 14.4248V3.60938C1.17188 2.64014 1.96045 1.85156 2.92969 1.85156H17.0703C18.0396 1.85156 18.8281 2.64014 18.8281 3.60938V14.4248Z" fill="url(#paint0_linear_103_6)" />
    <path d="M16.0054 5.88477H7.77344C7.4498 5.88477 7.1875 6.14722 7.1875 6.4707C7.1875 6.79434 7.4498 7.05664 7.77344 7.05664H16.0054C16.329 7.05664 16.5913 6.79434 16.5913 6.4707C16.5913 6.14722 16.329 5.88477 16.0054 5.88477Z" fill="black" />
    <path d="M16.0054 5.88477H7.77344C7.4498 5.88477 7.1875 6.14722 7.1875 6.4707C7.1875 6.79434 7.4498 7.05664 7.77344 7.05664H16.0054C16.329 7.05664 16.5913 6.79434 16.5913 6.4707C16.5913 6.14722 16.329 5.88477 16.0054 5.88477Z" fill="url(#paint1_linear_103_6)" />
    <path d="M3.99414 7.05664H5.90363C6.22726 7.05664 6.48956 6.79434 6.48956 6.4707C6.48956 6.14722 6.22726 5.88477 5.90363 5.88477H3.99414C3.6705 5.88477 3.4082 6.14722 3.4082 6.4707C3.4082 6.79434 3.6705 7.05664 3.99414 7.05664Z" fill="black" />
    <path d="M3.99414 7.05664H5.90363C6.22726 7.05664 6.48956 6.79434 6.48956 6.4707C6.48956 6.14722 6.22726 5.88477 5.90363 5.88477H3.99414C3.6705 5.88477 3.4082 6.14722 3.4082 6.4707C3.4082 6.79434 3.6705 7.05664 3.99414 7.05664Z" fill="url(#paint2_linear_103_6)" />
    <path d="M3.99414 9.6025H12.2261C12.5497 9.6025 12.812 9.3402 12.812 9.0166C12.812 8.69296 12.5497 8.43066 12.2261 8.43066H3.99414C3.6705 8.43066 3.4082 8.69296 3.4082 9.0166C3.4082 9.3402 3.6705 9.6025 3.99414 9.6025Z" fill="black" />
    <path d="M3.99414 9.6025H12.2261C12.5497 9.6025 12.812 9.3402 12.812 9.0166C12.812 8.69296 12.5497 8.43066 12.2261 8.43066H3.99414C3.6705 8.43066 3.4082 8.69296 3.4082 9.0166C3.4082 9.3402 3.6705 9.6025 3.99414 9.6025Z" fill="url(#paint3_linear_103_6)" />
    <path d="M16.0062 8.43066H14.0967C13.773 8.43066 13.5107 8.69296 13.5107 9.0166C13.5107 9.3402 13.773 9.6025 14.0967 9.6025H16.0062C16.3298 9.6025 16.5921 9.3402 16.5921 9.0166C16.5921 8.69296 16.3298 8.43066 16.0062 8.43066Z" fill="black" />
    <path d="M16.0062 8.43066H14.0967C13.773 8.43066 13.5107 8.69296 13.5107 9.0166C13.5107 9.3402 13.773 9.6025 14.0967 9.6025H16.0062C16.3298 9.6025 16.5921 9.3402 16.5921 9.0166C16.5921 8.69296 16.3298 8.43066 16.0062 8.43066Z" fill="url(#paint4_linear_103_6)" />
    <path d="M16.0054 10.9775H7.77344C7.4498 10.9775 7.1875 11.2398 7.1875 11.5635C7.1875 11.887 7.4498 12.1494 7.77344 12.1494H16.0054C16.329 12.1494 16.5913 11.887 16.5913 11.5635C16.5913 11.2398 16.329 10.9775 16.0054 10.9775Z" fill="black" />
    <path d="M16.0054 10.9775H7.77344C7.4498 10.9775 7.1875 11.2398 7.1875 11.5635C7.1875 11.887 7.4498 12.1494 7.77344 12.1494H16.0054C16.329 12.1494 16.5913 11.887 16.5913 11.5635C16.5913 11.2398 16.329 10.9775 16.0054 10.9775Z" fill="url(#paint5_linear_103_6)" />
    <path d="M5.90363 10.9775H3.99414C3.6705 10.9775 3.4082 11.2398 3.4082 11.5635C3.4082 11.887 3.6705 12.1494 3.99414 12.1494H5.90363C6.22726 12.1494 6.48956 11.887 6.48956 11.5635C6.48956 11.2398 6.22726 10.9775 5.90363 10.9775Z" fill="black" />
    <path d="M5.90363 10.9775H3.99414C3.6705 10.9775 3.4082 11.2398 3.4082 11.5635C3.4082 11.887 3.6705 12.1494 3.99414 12.1494H5.90363C6.22726 12.1494 6.48956 11.887 6.48956 11.5635C6.48956 11.2398 6.22726 10.9775 5.90363 10.9775Z" fill="url(#paint6_linear_103_6)" />
    <defs>
      <linearGradient id="paint0_linear_103_6" x1="0" y1="9.0171" x2="20" y2="9.0171" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F0631B" />
        <stop offset="1" stopColor="#BE01B6" />
      </linearGradient>
      <linearGradient id="paint1_linear_103_6" x1="7.1875" y1="6.4707" x2="16.5913" y2="6.4707" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F0631B" />
        <stop offset="1" stopColor="#BE01B6" />
      </linearGradient>
      <linearGradient id="paint2_linear_103_6" x1="3.4082" y1="6.4707" x2="6.48956" y2="6.4707" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F0631B" />
        <stop offset="1" stopColor="#BE01B6" />
      </linearGradient>
      <linearGradient id="paint3_linear_103_6" x1="3.4082" y1="9.0166" x2="12.812" y2="9.0166" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F0631B" />
        <stop offset="1" stopColor="#BE01B6" />
      </linearGradient>
      <linearGradient id="paint4_linear_103_6" x1="13.5107" y1="9.0166" x2="16.5921" y2="9.0166" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F0631B" />
        <stop offset="1" stopColor="#BE01B6" />
      </linearGradient>
      <linearGradient id="paint5_linear_103_6" x1="7.1875" y1="11.5635" x2="16.5913" y2="11.5635" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F0631B" />
        <stop offset="1" stopColor="#BE01B6" />
      </linearGradient>
      <linearGradient id="paint6_linear_103_6" x1="3.4082" y1="11.5635" x2="6.48956" y2="11.5635" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F0631B" />
        <stop offset="1" stopColor="#BE01B6" />
      </linearGradient>
    </defs>
  </svg>
);

export default Transcript;
