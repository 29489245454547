import React from 'react';
import { SvgSize } from './types';

const Page = (props: SvgSize) => (
  <svg width={props?.size ?? '20'} height={props?.size ?? '29'} viewBox="0 0 20 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M0.500009 2.29827V26.9153C0.500075 27.2109 0.619239 27.4936 0.829951 27.6982C1.04066 27.9028 1.32468 28.0114 1.61635 27.9991L18.3756 27.2829C18.9435 27.2589 19.3941 26.7901 19.4024 26.2147L19.6711 6.64639L13.79 0.787231L1.5303 1.20975C0.953297 1.23226 0.497675 1.71363 0.500009 2.29827Z" fill="var(--white)" />
    <path fillRule="evenodd" clipRule="evenodd" d="M0.500009 2.29827V26.9153C0.500075 27.2109 0.619239 27.4936 0.829951 27.6982C1.04066 27.9028 1.32468 28.0114 1.61635 27.9991L18.3756 27.2829C18.9435 27.2589 19.3941 26.7902 19.4024 26.2147L19.6711 6.64639L13.79 0.787231L1.5303 1.20975C0.953297 1.23226 0.497675 1.71363 0.500009 2.29827V2.29827Z" stroke="var(--textPrimary)" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M13.7865 0.791992V5.70585C13.7862 5.95666 13.8844 6.19728 14.0596 6.37463C14.2347 6.55198 14.4724 6.65147 14.7201 6.65115H19.6711L13.7865 0.791992Z" fill="var(--white)" />
    <path fillRule="evenodd" clipRule="evenodd" d="M13.7865 0.791992V5.70585C13.7862 5.95666 13.8844 6.19728 14.0596 6.37463C14.2347 6.55198 14.4724 6.65147 14.7201 6.65115H19.6711L13.7865 0.791992Z" stroke="var(--textPrimary)" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.7229 4.97188L10.3396 4.72839" stroke="var(--textPrimary)" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.7229 7.63711L10.3396 7.4342" stroke="var(--textPrimary)" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.93625 10.4824L16.3622 10.6973" stroke="var(--textPrimary)" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.93625 13.4973L16.3622 13.7468" stroke="var(--textPrimary)" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.93625 16.7355H15.9814" stroke="var(--textPrimary)" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.061 19.9735H15.9814" stroke="var(--textPrimary)" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.4005 22.8931L15.9814 22.6353" stroke="var(--textPrimary)" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Page;


