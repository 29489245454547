import ReactSelect, { Props, GroupBase, components, DropdownIndicatorProps } from 'react-select';

import styled, { css } from 'styled-components';
import Caret from '../Icons/Caret';

const StyledSelect = styled(ReactSelect<Option, boolean, GroupBase<Option>>) <{ $size?: 'small' | 'medium' | "large"; }>`
height: fit-content;
.rs__indicator-separator {
  display: none;
}

.rs__control,
.rs__single-value,
.rs__input-container {
  color: var(--text);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.15px;

  &:hover {
    border-color: var(--gray400);
  }
}

.rs__control {
  align-items: center;
  box-sizing: border-box;
  color: var(--text);
  border-radius: var(--standardRadius);
  background-color: var(--background);
  border: var(--standardBorder);
  color: var(--text);
  display: inline-flex;
  gap: 5px;
  padding: 0px 15px;

  &:focus-within {
    border-color: var(--brand-secondary);
  }
}

.rs__placeholder {
  color: var(--textSecondary);
}

.rs__menu {
  background-color: var(--background);
  border: var(--standardBorder);
  border-radius: var(--standardRadius);
  width: 100%;
  color: var(--text);
  padding: 8px;
  margin-top: 6px;
  filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
}

.rs__option {
  background-color: var(--background);
  color: var(--text);
  cursor: pointer;
  padding: 8px;

  &:hover {
    background-color: var(--primary100);
  }
}
.rs__option--is-focused:not(.rs__option--is-selected) {
    background-color: var(--primary100);
}

.rs__clear-indicator {
  background: var(--brand-primary);
  border-radius: 100%;
  color: var(--white);
  margin-right: 10px;
}

.rs__clear-indicator > svg {
  width: 17px;
  height: 17px;
}

.rs__control--menu-is-open .rs__dropdown-indicator {
  transform: rotate(180deg);
  transition: transform 300ms ease-in-out;
}

.rs__dropdown-indicator {
  color: var(--brand-primary);
  transition: transform 300ms ease-in-out;
}

 ${({ $size }) => $size && css`
    .rs__control {
      ${$size == 'small' && 'width: 100px'};
      ${$size == 'medium' && 'width: 200px'};
      ${$size == 'large' && 'width: 400px'};
    }
  `}
`;


const DropdownIndicator = (
  props: DropdownIndicatorProps<Option, boolean, GroupBase<Option>>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <Caret />
    </components.DropdownIndicator>
  );
};



interface Option {
  value?: string,
  label: string,
}

interface CustomProps extends Props<Option, boolean, GroupBase<Option>> {
  size?: "small" | "medium" | "large";
}

const Select = ({ options, onChange, size, ...props }: CustomProps) => {

  return (
    <StyledSelect
      unstyled
      classNamePrefix="rs"
      components={{ DropdownIndicator }}
      onChange={onChange}
      options={options}
      {...props}
      $size={size}
    />
  );
};
export default Select;