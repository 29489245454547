import React, { useEffect, useState, useMemo } from 'react';
import { Outlet, useLoaderData, useNavigate } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';
import useInterval from 'use-interval';
import { isMobile } from 'react-device-detect';

import {
  getRenewedUserInfo, mapObject, groupBy, refreshUploadedTranscriptsRequest, backendMeetingDataToLocalMeetingData,
  isBrieflySuccess, isLoggedIn,
} from '../utils/genericUtils';
import { UserNotLoggedInState } from './edgeCaseStates/UserNotLoggedInState';
import { NoCallsYet } from './edgeCaseStates/NoCallsYet';
import { MobileNotAvailableState } from './edgeCaseStates/MobileNotAvailableState';
import { NotChromeState } from './edgeCaseStates/NotChromeState';
import { BlockedAccessState } from './edgeCaseStates/BlockedAccessState';
import outOfCreditsError from '../images/outOfCreditsError.svg';
import ZoomStep1Png from '../images/zoom-step-1.png';
import ZoomStep2Png from '../images/zoom-step-2.png';
import ZoomStep3Png from '../images/zoom-step-3.png';
import ZoomStep4Png from '../images/zoom-step-4.png';
import MsTeamsStep1Png from '../images/ms-teams-step-1.png';
import MsTeamsStep2Png from '../images/ms-teams-step-2.png';
import { useUserData } from './hooks/useUserData';
import TopNav from './TopNav';
import FeedbackComponents from './FeedbackComponents';
import ZoomOnboardingPopup from './ZoomOnboardingPopup';
import SideNav from './SideNav';
import useUserEventTracking from "../utils/useUserEventTracking";
import { AlertIcon, CheckmarkCircleIcon, XIcon } from './Icons';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '../theme';
import { ENVIRONMENT } from '../config/config';

function RenewUserInfoIntervalComponent() {
  const TICK_TIME = 500;
  const TICKS_TIL_RENEW = 50;

  const [_tick, setTick] = useState(TICKS_TIL_RENEW);

  useInterval(() => {

    setTick(prevTick => {
      if (prevTick === TICKS_TIL_RENEW || localStorage.getItem('user-force-refresh-now')) {
        getRenewedUserInfo();
        localStorage.removeItem('user-force-refresh-now');
        return 0;
      }
      return prevTick + 1;
    });

  }, TICK_TIME);
}

export default function Root() {
  const [userData] = useUserData();

  const LOGGED_IN_AT_LEAST_ONCE_KEY = !!localStorage.getItem('LOGGED_IN_AT_LEAST_ONCE_KEY');

  const { meetingDataById: initialMeetingDataById, brieflyIsInstalled, brieflyUserData } = useLoaderData();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [meetingDataById, setMeetingDataById] = useState(initialMeetingDataById);

  const chronologicalMeetingDataByDay = useMemo(() => {
    return mapObject(groupBy(Object.values(meetingDataById).filter(meeting => meeting.meetingStartDate), meeting => meeting.meetingStartDate.split('T')[0]), lst => lst.sort(function(a, b) {
      return new Date(b.meetingStartDate) - new Date(a.meetingStartDate);
    }));
  }, [meetingDataById]);

  const loadingMeetingIds = Object.values(meetingDataById).filter(m => ["ongoing", "processing"].includes(m.meeting_status)).map(x => x.transcriptId);

  useInterval(() => {
    if (isLoggedIn()) {
      refreshUploadedTranscriptsRequest({
        refresh_transcript_ids: loadingMeetingIds,
        get_transcript_ids_after: Math.max(...Object.values(meetingDataById).filter(x => x.isBackend).filter(x => x.transcriptId).map(x => x.transcriptId).concat([0]))
      }).then(data => {
        if (data.error) {
          return;
        }
        const newMeetingDataById = { ...meetingDataById };

        const transcripts = data.transcripts;

        for (const transcript of transcripts) {
          const formattedMeeting = backendMeetingDataToLocalMeetingData(transcript);

          newMeetingDataById[formattedMeeting.meetingId] = formattedMeeting;

          setMeetingDataById(newMeetingDataById);
        }
      });
    }
  }, 5000);

  useUserEventTracking({ id: userData?.profile?.id });

  const [referrerData] = useState(localStorage.getItem("SUBMITTED_REFERRER_DATA"));
  const [showReferrerNotification, setShowReferrerNotification] = useState(!!referrerData);

  const [showSlackTip, setShowSlackTip] = useState(false);

  useEffect(() => {
    if (showReferrerNotification) {
      localStorage.removeItem("SUBMITTED_REFERRER_DATA");
    }
  });

  const isReferrerSubmitSuccessful = referrerData === userData.referrer_data;

  const {
    subscription_is_active,
    admin_user,
    managed_users_not_confirmed,
    managed_users,
    subscription_plan,
  } = userData;

  const isTeam = subscription_is_active && managed_users.concat(managed_users_not_confirmed).length > 0 && subscription_plan !== 'STANDARD_GIVEN_FOR_FREE';
  const isPro = !isTeam && subscription_is_active && subscription_plan !== 'STANDARD_GIVEN_FOR_FREE';
  const isTeamMember = admin_user && subscription_plan !== 'STANDARD_GIVEN_FOR_FREE';
  const isFreePro = subscription_plan === 'STANDARD_GIVEN_FOR_FREE';

  const isHubspotConnected = !!userData?.is_hubspot_connected || !!userData?.team_hubspot_admin;
  const isSalesforceConnected = !!userData?.is_salesforce_connected || !!userData?.team_salesforce_admin;
  const isGrowthOpportunitiesVisible = !!userData?.hubspot_integration_is_visible || !!userData?.salesforce_integration_is_visible;
  const isSlackConnected = !!userData?.slack_workspace_name;

  const isHubspotAdmin = isHubspotConnected;
  const isSalesforceAdmin = isSalesforceConnected;

  const isTeamAccount = managed_users ? (managed_users.concat(managed_users_not_confirmed)).length > 0 : false;
  const isTeamMemberAccount = !!admin_user;

  const urlSearchParams = new URLSearchParams(window.location.search);
  const searchQueryParam = urlSearchParams.get('search');


  const [showZoomTranscriptInfoPopup, setShowZoomTranscriptInfoPopup] = useState(false);
  const [showZoomOnboardingPopup, setShowZoomOnboardingPopup] = useState(false);
  const [showMsTeamsTranscriptInfoPopup, setShowMsTeamsTranscriptInfoPopup] = useState(false);

  const [showOutOfCreditsPopup, setShowOutOfCreditsPopup] = useState(false);

  useEffect(() => {
    if (isBrieflySuccess()) {
      return;
    }
    if (window.location.pathname === '/' && chronologicalMeetingDataByDay && Object.keys(chronologicalMeetingDataByDay).length > 0) {
      const latestMeeting = Object.entries(chronologicalMeetingDataByDay).sort((a, b) => new Date(b[0]) - new Date(a[0]))[0][1][0];

      if (latestMeeting.meetingId === "demo-meeting") {
        return navigate({
          pathname: `/meeting/${latestMeeting.meetingId}`,
          search: searchQueryParam ? `search=${searchQueryParam}` : ''
        });
      }

      navigate({
        pathname: `/meetings`,
        search: searchQueryParam ? `search=${searchQueryParam}` : ''
      });
    }
  }, []);

  var body = null;

  if (isMobile) {
    body = <div id="content">
      <MobileNotAvailableState />
    </div>;
  } else if (ENVIRONMENT !== 'DEVELOPMENT' && !window.chrome) {
    body = <div id="content">
      <NotChromeState />
    </div>;
  } else if (userData.organization && userData.organization.block_free_access && !(isTeamAccount || isTeamMemberAccount)) {
    body = <div id="content">
      <BlockedAccessState organizationData={userData.organization} />
    </div>;
  } else if (brieflyIsInstalled && userData.error) {
    body = <div id="content" className={LOGGED_IN_AT_LEAST_ONCE_KEY ? 'LOGGED_IN_AT_LEAST_ONCE_KEY' : ''}>
      <GoogleOAuthProvider clientId="576404817524-7ghb7h6lrj86l827mhddqfal0c61bj9n.apps.googleusercontent.com">
        <UserNotLoggedInState />
      </GoogleOAuthProvider>
    </div>;
  } else if (Object.keys(chronologicalMeetingDataByDay).length === 0) {
    body = <div id="content">
      <div id="detail">
        <NoCallsYet />
      </div>
    </div>;
  } else {
    var innerBody = null;

    if (isBrieflySuccess()) {
      innerBody = <>This is briefly success</>;
    } else {
      innerBody = <>
        <SideNav setShowSlackTip={setShowSlackTip} setShowZoomOnboardingPopup={setShowZoomOnboardingPopup} />
        <div id="detail">
          <Outlet context={{
            setShowSlackTip,
            brieflyUserData,
            brieflyIsInstalled,
            meetingDataById,
            setShowZoomTranscriptInfoPopup,
            setShowZoomOnboardingPopup,
            setShowMsTeamsTranscriptInfoPopup,
            setShowOutOfCreditsPopup,
            searchWords: searchQueryParam ? searchQueryParam.split(/\s+/) : null
          }} />
        </div>
      </>;
    }

    body = <div id="content">
      {innerBody}
    </div>;
  }
  if (!userData) return <></>;
  return (
    <div id="body" {...{
      "subscription-type": isTeam ? 'is-team' : isTeamMember ? 'is-team-member' : isPro || isFreePro ? 'is-pro' : 'is-free',
      "hubspot-connected": isHubspotConnected ? 'true' : 'false',
      "salesforce-connected": isSalesforceConnected ? 'true' : 'false',
      "is-hubspot-admin": isHubspotAdmin ? 'true' : 'false',
      "is-salesforce-admin": isSalesforceAdmin ? 'true' : 'false',
      "slack-connected": isSlackConnected ? 'true' : 'false',
      "growth-opportunities-visible": isGrowthOpportunitiesVisible ? 'true' : 'false'
    }}
    >
      <RenewUserInfoIntervalComponent />
      {!userData?.error && <FeedbackComponents />}
      <TopNav style={{ display: brieflyIsInstalled && userData.error ? "none" : "flex" }} />
      {showSlackTip && (
        <div className="transcript-info-popup-container" id="slack-tip-popup">
          <div className="popup-background" style={{}} onClick={() => setShowSlackTip(false)}></div>
          <div className="popup-content">
            <div className="close-icon" onClick={() => setShowSlackTip(false)}>
              <XIcon />
            </div>

            <h1>📝 {t('settings.slack-about.title', 'Get all your updates in one place!')}</h1>

            <div className='gray-area' style={{ background: 'var(--backgroundSecondary)' }}>
              <h5 style={{ marginTop: 5 }}>📑 {t('settings.slack-about.subtitle', 'Post Call AI Summaries')}</h5>
              <ul style={{ marginBottom: 5 }}>
                <li>
                  <Trans i18nKey={'settings.slack-about.bullet-1'}>
                    <strong>Find Them Easily</strong>: Your summaries pop up in a private Slack channel or DM, just for you. Set
                    it up on the next step.
                  </Trans>
                </li>
                <li>
                  <Trans i18nKey={'settings.slack-about.bullet-2'}>
                    <strong>All the Good Stuff</strong>: Everything you loved in the email summaries, now also in Slack (and still
                    in the dashboard and extension).
                  </Trans>
                </li>
                <li>
                  <Trans i18nKey={'settings.slack-about.bullet-3'}>
                    <strong>Your Choice</strong>: Love getting summaries in Slack but don’t want duplicate in emails? You can turn
                    off email notifications in settings.
                  </Trans>
                </li>
              </ul>
            </div>

            <Button medium onClick={() => {
              setShowSlackTip(false);
              navigate('/settings/integrations/slack');
            }} >
              {t('settings.slack-about.manage', 'Manage Slack Integration')}
            </Button>

          </div>
        </div>
      )}
      {showOutOfCreditsPopup && (
        <div className="transcript-info-popup-container out-of-credits">
          <div className="popup-background" onClick={() => setShowOutOfCreditsPopup(false)}></div>
          <div className="popup-content">
            <div className="close-icon" onClick={() => setShowOutOfCreditsPopup(false)}>
              <XIcon />
            </div>
            <img src={outOfCreditsError} alt="out of credits error image" />

            <h1>You don&apos;t have any AI credits left for this month...</h1>

            <p>
              You can wait until the end of the month to receive an additional 5 credits, or you can upgrade your
              membership to Briefly PRO
            </p>

            <Button style={{ margin: "auto", color: "var(--white)" }} onClick={() => {
              navigate('/settings/subscriptions');
              setShowOutOfCreditsPopup(false);
            }}>
              Manage subscription
            </Button>

          </div >
        </div >
      )}
      {showZoomOnboardingPopup && <ZoomOnboardingPopup onClose={() => setShowZoomOnboardingPopup(false)} />}
      {showZoomTranscriptInfoPopup && (
        <div className="transcript-info-popup-container">
          <div className="popup-background" onClick={() => setShowZoomTranscriptInfoPopup(false)}></div>
          <div className="popup-content">
            <div className="close-icon" onClick={() => setShowZoomTranscriptInfoPopup(false)}>
              <XIcon size="16" color="var(--brand-primary)" />
            </div>
            <h1>How to get a Zoom transcript</h1>
            <p>
              There are multiple ways to obtain a Zoom transcript. <b>Follow the instructions below to get transcripts
                that work best with BrieflyCreate.</b>
            </p>
            <h2>1 - Activate Closed Captioning</h2>
            <ul>
              <li>Go to your profile setting</li>
              <li>Click on In Meeting (Advanced)</li>
              <li>Scroll down and enable the following options</li>
              <li className="margin-left">Automated captions</li>
              <li className="margin-left">Full transcript</li>
              <li className="margin-left">Save Captions</li>
            </ul>
            <img src={ZoomStep1Png} />
            <h2>2 - Save transcripts from Zoom</h2>
            <ul>
              <li>During a Zoom call, click on &quot;More&quot; (represented by three dots) in the bottom bar and Select
                &quot;Captions&quot;
              </li>
            </ul>
            <img src={ZoomStep2Png} />
            <ul>
              <li>Choose “View Full Transcript”</li>
            </ul>
            <img src={ZoomStep3Png} />
            <ul>
              <li>Click on “Save Transcript”</li>
            </ul>
            <img src={ZoomStep4Png} />
            <h2>3 - For best results</h2>
            <ul>
              <li>Ensure that you upload a transcript that includes the participants&apos; names.</li>
            </ul>
          </div>
        </div >
      )}
      {showMsTeamsTranscriptInfoPopup && (
        <div className="transcript-info-popup-container">
          <div className="popup-background" onClick={() => setShowMsTeamsTranscriptInfoPopup(false)}></div>
          <div className="popup-content">
            <div className="close-icon" onClick={() => setShowMsTeamsTranscriptInfoPopup(false)}>
              <XIcon size="16" color="var(--brand-primary)" />
            </div>
            <h1>How to get a MS Teams transcript</h1>
            <p>
              There are multiple ways to obtain a MS Teams transcript. <b>Follow the instructions below to get
                transcripts that work best with BrieflyCreate.</b>
            </p>
            <h2>1 - Start live transcription</h2>
            <ul>
              <li>Go to the meeting controls and select the &quot;More actions&quot; icon (represented by three dots).</li>
              <li>Choose &quot;Start transcription.&quot;</li>
              <li>Participants will receive a notification, and the transcript will appear on the right side of the
                screen.
              </li>
            </ul>
            <img src={MsTeamsStep1Png} />
            <h2>2 - Save transcripts</h2>
            <ul>
              <li>After the meeting, the transcript is immediately available in the meeting event on the calendar.</li>
              <li>Select &quot;Calendar&quot; on the left in Teams.</li>
              <li>Open the meeting event and select the transcript.</li>
              <li>Click &quot;Download&quot; and choose .vtt</li>
            </ul>
            <img src={MsTeamsStep2Png} />

            <h2>3 - For best results</h2>
            <ul>
              <li>Ensure that you upload a transcript that includes the participants&apos; names.</li>
            </ul>
          </div >
        </div >
      )}

      {showReferrerNotification && (
        <div id="briefly-notification" className={isReferrerSubmitSuccessful ? '' : 'error'}
          style={{ display: 'block', width: 400 }}>
          <div>
            <CheckmarkCircleIcon color="var(--green500)" size="16" className="icon-error" />

            <AlertIcon color="var(--brand-primary)" className="icon-not-error" size="16" />

            <span id="notification-header">
              {isReferrerSubmitSuccessful ? "Referral Under Review!" : "Referrals are available to new users only"}
            </span>
            <XIcon color="var(--brand-primary)" onClick={() => setShowReferrerNotification(false)} size="12" />
          </div>
          <p id="notification-text">
            {isReferrerSubmitSuccessful ? "We've received your referral request. After we verify the email you entered, both of you will receive rewards." : "The referral program is for first-time users only."}
          </p>
        </div >
      )}
      {navigator.platform.indexOf("Win32") != -1 && <style>
        {`
      *::-webkit-scrollbar {
      width: 0px;
          }
        `}
      </style >
      }

      {body}
    </div >
  );
}
