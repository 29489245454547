import React from 'react';
import { SvgSize } from './types';

const Panels = (props: SvgSize) => (
  <svg width={props?.size ?? '16'} height={props?.size ?? '16'} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" fillRule="evenodd" d="M2.702 2.643C3.231 2.146 3.972 2 4.772 2H8.53c.8 0 1.542.146 2.07.643.54.507.702 1.232.702 2.008V19.35c0 .776-.163 1.5-.702 2.008-.528.497-1.27.643-2.07.643H4.772c-.8 0-1.541-.146-2.07-.643C2.162 20.85 2 20.125 2 19.349V4.65c0-.776.163-1.5.702-2.008Zm.956 1.016c-.128.12-.263.373-.263.992V19.35c0 .619.135.871.263.992.14.13.435.264 1.114.264H8.53c.679 0 .975-.134 1.114-.264.128-.12.263-.373.263-.992V4.65c0-.619-.135-.871-.263-.992-.139-.13-.435-.264-1.114-.264H4.772c-.679 0-.975.134-1.114.264ZM13.4 2.643C13.928 2.146 14.67 2 15.47 2h3.758c.8 0 1.541.146 2.07.643.54.507.702 1.232.702 2.008v6.326c0 .776-.163 1.5-.702 2.008-.529.497-1.27.643-2.07.643H15.47c-.8 0-1.542-.146-2.07-.643-.54-.508-.702-1.232-.702-2.008V4.65c0-.776.163-1.5.702-2.008Zm.956 1.016c-.128.12-.263.373-.263.992v6.326c0 .618.135.871.263.992.139.13.435.264 1.114.264h3.758c.679 0 .975-.134 1.114-.264.128-.121.263-.373.263-.992V4.65c0-.619-.135-.871-.263-.992-.14-.13-.435-.264-1.114-.264H15.47c-.679 0-.975.134-1.114.264ZM13.4 15.666c.528-.497 1.27-.643 2.07-.643h3.758c.8 0 1.541.146 2.07.643.54.508.702 1.232.702 2.008v1.675c0 .776-.163 1.5-.702 2.008-.529.497-1.27.643-2.07.643H15.47c-.8 0-1.542-.146-2.07-.643-.54-.507-.702-1.232-.702-2.008v-1.675c0-.776.163-1.5.702-2.008Zm.956 1.017c-.128.12-.263.373-.263.991v1.675c0 .619.135.871.263.992.139.13.435.264 1.114.264h3.758c.679 0 .975-.134 1.114-.264.128-.12.263-.373.263-.992v-1.675c0-.618-.135-.87-.263-.991-.14-.131-.435-.264-1.114-.264H15.47c-.679 0-.975.133-1.114.264Z" clipRule="evenodd" />
  </svg>
);

export default Panels;
