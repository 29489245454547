import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { formatDatetime, highlightText, plural, removeMeetingById, removeBackendMeetingById, } from '../../utils/genericUtils';
import { FileUploadIcon, GoogleMeetLogo, TrashCanIcon, ZoomLogo } from '../Icons';
import Alert from "../Reusable/Alert";
import { Button } from '../../theme';

import styled from "styled-components";
import { LoaderMeeting } from "../../types/LoaderData";
import Attendees from "../Reusable/Attendees";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Call = styled(Link)`
  display: grid;
  grid-template-areas:
      "label date attendees delete"
      "search search search search";
  grid-template-columns: minmax(18rem, auto) 15rem 10rem min-content;
  column-gap: 32px;
  border: var(--standardBorder);
  border-radius: var(--standardRadius);
  padding: 0 16px;
  width: 100%;
  min-width: fit-content;
  box-sizing: border-box;
  
  &:hover, &:focus-within {
    background: linear-gradient(var(--focusGradientSecondary));
  }
`;

const Label = styled.span`
  display: flex;
  grid-area: label;
  gap: 10px;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
`;

const IconWrapper = styled.div`
  width: 22px;
`;

const StartDate = styled.span`
  font-size: 12px;
  color: var(--textSecondary);
  display: inline-flex;
  align-items: center;
  grid-area: date;
`;

const AlignedAttendees = styled(Attendees)` 
  grid-area: attendees;
  align-self: center;
`;

const Delete = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  grid-area: delete;
`;

const DeleteBtn = styled(Button)`
`;

const SearchResult = styled.div`
  padding: 0 1rem;
  grid-area: search;
`;

function getMeetingLabel(m) {
  if (m.meeting_status && m.meeting_status !== 'ready') {
    if (m.meeting_status === "ongoing") {
      if (!m.recording_started_zoom) {
        if (!m.meeting_ended_zoom) {
          return m.meetingName;
        } else {
          return "Meeting ended. Recording was not started";
        }
      } else {
        return "Transcribing...";
      }
    }
    if (m.meeting_status === "processing") {
      return "Processing...";
    }
    if (m.meeting_status === "captions_missing") {
      return "Transcript not available";
    }
    if (m.meeting_status === "recording_permission_denied") {
      return "Permission denied";
    }

    return "error";
  }
  return m.meetingName;
}
function getMeetingIcon({ m, style }: { m: LoaderMeeting, style?: React.CSSProperties; }) {
  if (m.meetingPlatform === 'zoom') {
    return <ZoomLogo size={22} style={style} />;
  }
  if (m.meetingPlatform == 'Google Meets') {
    return <GoogleMeetLogo width="22" style={{ ...style }} />;
  }
  if (m.isBackend) {
    return <FileUploadIcon size="22" style={{ color: "var(--brand-primary)", ...style }} />;
  }
  return <GoogleMeetLogo width="22" style={{ ...style }} />;
}

interface Meeting extends LoaderMeeting {
  outputContextStrings?: string[],
  summaryContextStrings?: string[],
  outputMatchesCount?: number,
  summaryMatchesCount?: number,
  transcriptMatchesCount?: number,
  transcriptContextStrings?: string[],
}

interface Props {
  setRemovedMeetingIds: React.Dispatch<React.SetStateAction<string[]>>;
  m: Meeting;
  searchQuery?: string;
  removedMeetingIds: string[];
  tasks: { complete: boolean; }[];
}


const CallListItem = ({ setRemovedMeetingIds, m, searchQuery, removedMeetingIds, tasks }: Props) => {
  const { t } = useTranslation();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const { state } = useLocation();
  const lastPageVisited: string | undefined = state?.from;
  const lastMeetingVisited = lastPageVisited?.split('meeting/').at(-1);
  const scrollTargetRef = React.useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (lastMeetingVisited && m.meetingId === lastMeetingVisited) {
      scrollTargetRef?.current?.scrollIntoView({ behavior: 'instant', block: 'center' });
      scrollTargetRef?.current?.focus();
    }
  }, []);

  const hasTasks = tasks.some(({ complete }) => complete === false);

  const handleDeleteCall = useCallback(() => {
    m.isBackend ? removeBackendMeetingById(m.meetingId) : removeMeetingById(m?.meetingId);
    setRemovedMeetingIds(removedMeetingIds.concat([m.meetingId]));
  }, [removedMeetingIds, setRemovedMeetingIds, setIsAlertOpen]);


  const params = `search=${searchQuery}&${m.transcriptContextStrings ? 'tab=transcript' : m.summaryContextStrings ? 'tab=summary' : ''}`;

  return (
    <Container>
      <Alert
        open={Boolean(isAlertOpen)}
        onOpenChange={setIsAlertOpen}
        onConfirm={handleDeleteCall}
        cancelContent={t('call-list.delete-call.confirmation.cancel', 'Cancel')}
        confirmContent={t('call-list.delete-call.confirmation.confirm', 'Yes, delete this call')}
        description={t('call-list.delete-call.confirmation.description', 'This action cannot be reversed and this call and its associated data will be permanently removed.')}
        title={t('call-list.delete-call.confirmation.title', 'Are you sure you want to delete this call?')}
      />
      <Call
        ref={scrollTargetRef}
        key={m.meetingId}
        to={{
          pathname: `/meeting/${m.meetingId}`,
          search: searchQuery ? params : undefined
        }}
        state={{ searchQuery: location.search }}
      >
        <Label>
          {hasTasks && <span style={{ lineHeight: 0, color: 'var(--brand-primary)', fontSize: 24 }}>•</span>}
          <IconWrapper>{getMeetingIcon({ m })}</IconWrapper>
          {getMeetingLabel(m)}
        </Label>
        <StartDate>
          {m.meeting_status === "ongoing" ? <>
            <svg style={{ marginRight: 3 }} xmlns="http://www.w3.org/2000/svg" width="8" height="8"
              viewBox="0 0 8 8" fill="none">
              <circle opacity="0.6" cx="4" cy="4" r="2" fill="var(--brand-primary)" />
            </svg>
            {t('root-sidebar.call-item.in-progress', 'Recording in progress...')}
          </> : formatDatetime(m.meetingStartDate)}
        </StartDate>
        {m.attendeesByColor && m.attendeesByColor.length > 0 && m.meetingId && <AlignedAttendees limit={3} attendeesByColor={m.attendeesByColor} keyId={`call-list-${m.meetingId}`} />}
        <Delete>
          <DeleteBtn $icon onClick={(e) => {
            e.preventDefault();
            setIsAlertOpen(!isAlertOpen);
          }}>
            <TrashCanIcon />
          </DeleteBtn>
        </Delete>
        <SearchResult>
          {m.transcriptContextStrings && m.transcriptContextStrings.length > 0 && <div className="search-match-info">
            <Link className="search-badge-container"
              to={{ pathname: `/meeting/${m.meetingId}`, search: `search=${searchQuery}&tab=transcript` }}
              state={{ searchQuery: location.search }}>
              <div className="search-badge-name">Transcript</div>
              <div
                className="search-badge-count">{m.transcriptMatchesCount} {plural('match', 'matches', m.transcriptContextStrings.length)}</div>
            </Link>
            <p>
              {highlightText(m.transcriptContextStrings?.slice(0, 3)?.join(" ... ") + (m.transcriptContextStrings.length > 1 ? " ... " : ""), searchQuery?.split(/\s+/), { "fontWeight": "bold" })}
            </p>
          </div>}
          {m.summaryContextStrings && m.summaryContextStrings.length > 0 && <div className="search-match-info">
            <Link className="search-badge-container"
              to={{ pathname: `/meeting/${m.meetingId}`, search: `search=${searchQuery}&tab=summary` }}
              state={{ searchQuery: location.search }}
            >
              <div className="search-badge-name">AI Summary</div>
              <div
                className="search-badge-count">{m.summaryMatchesCount} {plural('match', 'matches', m.summaryContextStrings.length)}</div>
            </Link>
            <p>
              {highlightText(m.summaryContextStrings?.slice(0, 3)?.join(" ... ") + (m.summaryContextStrings.length > 1 ? " ... " : ""), searchQuery?.split(/\s+/), { "fontWeight": "bold" })}
            </p>
          </div>}
          {/* {m.outputContextStrings && m.outputContextStrings.length > 0 && <div className="search-match-info">
            <Link className="search-badge-container"
              to={{ pathname: `/meeting/${m.meetingId}`, search: `search=${searchQuery}&tab=create` }}
            >
              <div className="search-badge-name">Create</div>
              <div
                className="search-badge-count">{m.outputMatchesCount} {plural('match', 'matches', m.outputMatchesCount)}</div>
            </Link>
            <p>
              {highlightText(m.outputContextStrings?.slice(0, 3)?.join(" ... ") + (m.outputContextStrings.length > 1 ? " ... " : ""), searchQuery?.split(/\s+/), { "fontWeight": "bold" })}
            </p>
          </div>} */}
        </SearchResult>
      </Call>
    </Container>
  );

};

export default CallListItem;
