import { useContext, useEffect } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { useNavigate, useSearchParams } from "react-router-dom";
import { ChatThreadContext } from '../../ChatThreadProvider';
import { FlexCenterCenter } from '../../theme';
import { apiPost } from '../../utils/api';
import { ChatThread } from './ShowChat/types';

type Fields = 'uuid';

function NewChat(): JSX.Element {
  const navigate = useNavigate();
  const { setChatThread } = useContext(ChatThreadContext)
  const [searchParams] = useSearchParams();

  const handleSubmit = async (abortController: AbortController) => {
    const transcriptIds = (searchParams.get('transcriptIds') || '')
      .split(',')
      .filter((id) => id);

    const { chat_thread: chat } = await apiPost<Fields, ChatThread, 'chat_thread'>({
      path: '/chat-threads',
      body: { 'transcript_ids': transcriptIds },
      signal: abortController.signal,
    });

    setChatThread(chat);
    navigate(`/chat/${chat.uuid}`, { replace: true })
  }

  useEffect(() => {
    const abortController = new AbortController();

    handleSubmit(abortController)

    return () => abortController.abort();
  }, []);

  return <FlexCenterCenter><ThreeDots color='var(--primary200)' width={50} /></FlexCenterCenter>;
}

export default NewChat;
