import React, { FormEventHandler, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThreeDots } from 'react-loader-spinner';
import Markdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';

import { Button } from '../../../theme';
import formEntriesToBody from '../../../utils/formEntriesToBody';
import { Panels, SparklesIcon } from '../../Icons';
import { ChatBubble } from './ChatBubble';
import { Bubble, ChatControl, ChatInput, ChatInputWrapper, ChatLog, ChatLogContainer, ChatScroll, EmptyState, Form, SparkleContainer, SubmitButton, TemplateDialog } from './styles';
import Templates from './Templates';
import { Props } from './types';

function ShowChatChat({
  isLoading,
  isLoadingOlderMessages: isFetchingOlderMessages,
  loadingMessage,
  messages,
  onScrollOlderMessages,
  onSubmit,
  trackedOldestMessageId,
}: Props) {
  const { t } = useTranslation();
  const [message, setMessage] = useState<string>('');

  const bottomAnchorRef = useRef<HTMLSpanElement>(null);
  const scrollDivRef = useRef<HTMLDivElement>(null);
  const preAppendedScrollHeightRef = useRef<number>();
  const [showTemplates, setShowTemplates] = useState<boolean>(false);

  const scrollToBottom = (behavior: ScrollBehavior = 'smooth') => {
    if (bottomAnchorRef.current != null) {
      bottomAnchorRef.current.scrollIntoView({ behavior });
    }
  };

  const adjustScrollForOlderMessages = () => {
    const scrollDiv = scrollDivRef.current;
    const currentScrollHeight = scrollDiv?.scrollHeight ?? 0;
    const prevScrollHeight = preAppendedScrollHeightRef.current ?? 0;
    const scrollHeightDiff = currentScrollHeight - prevScrollHeight;
    // Immediately adjust the scroll and then make a smooth transition to see the first prev message
    scrollDiv?.scrollTo({ top: scrollHeightDiff });
    scrollDiv?.scrollTo({ top: scrollHeightDiff - 60, behavior: 'smooth' });
  };

  useEffect(() => {
    adjustScrollForOlderMessages();
  }, [trackedOldestMessageId]);

  useEffect(() => {
    scrollToBottom('instant');
  }, [loadingMessage, isLoading]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    const data = formEntriesToBody(new FormData(form));

    if (typeof data.message !== 'string') {
      return;
    }

    const body = { message: data.message };

    form.reset();
    setMessage('');
    setTimeout(() => scrollToBottom(), 100);
    await onSubmit(body);
  };

  const listenToTextAreaHeight = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.currentTarget.value);
  };

  const handleTextAreaEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const assistKeysPressed = e.ctrlKey || e.shiftKey || e.altKey;
    if (e.key === 'Enter' && !assistKeysPressed && e.currentTarget.value.trim() !== '') {
      e.preventDefault();

      // Avoid sending messages when the assistant has not yet responded
      if(isLoading) return;

      e.currentTarget.form?.dispatchEvent(new Event('submit', { bubbles: true }));
      return;
    }
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.currentTarget;
    if (target.scrollTop === 0 && !isFetchingOlderMessages && messages.length > 0) {
      const lastMessageId = messages[0].id;
      preAppendedScrollHeightRef.current = scrollDivRef.current?.scrollHeight ?? 0;
      onScrollOlderMessages(lastMessageId);
    }
  };

  return (
    <ChatLogContainer>
      <ChatScroll onScroll={handleScroll} ref={scrollDivRef}>
        <ChatLog>
          {!isLoading && messages.length === 0 && (
            <EmptyState>{t('chat-log.empty-state-notice', 'No Messages')}</EmptyState>
          )}
          {isFetchingOlderMessages && <ThreeDots color='var(--primary200)' width={40} />}
          {messages.map((message, index) => (
            <ChatBubble
              key={message.id}
              message={message}
              icon={message.role === 'assistant' && messages[index - 1]?.role != 'assistant' ? <SparkleContainer><SparklesIcon /></SparkleContainer> : null}
            />
          ))}
          {loadingMessage && (
            <Bubble className='assistant streaming-message'><Markdown remarkPlugins={[remarkGfm, remarkBreaks]}>{loadingMessage}</Markdown></Bubble>
          )}
          {isLoading && !loadingMessage && <ThreeDots color='var(--primary200)' width={40} />}
          <span ref={bottomAnchorRef}>&nbsp;</span>
        </ChatLog >
      </ChatScroll>
      <ChatControl>
        <TemplateDialog
          trigger={<Button forwardedAs="span" inline small outline>
            <Panels size={24} />
          </Button>}
          open={showTemplates}
          onOpenChange={setShowTemplates}
          onOpenAutoFocus={(event) => event.preventDefault()}
          maxWidth="800px"
          title={t('meeting-templates.title', 'Select a Prompt')}
          titleStyle={{ fontSize: '18px', marginTop: 0, marginBottom: '10px' }}
          hideClose
        >
          <Templates onComplete={(maybePrompt) => {
            setShowTemplates(false);
            maybePrompt && setMessage(maybePrompt.prompt);
          }} />
        </TemplateDialog>
        <Form onSubmit={handleSubmit}>
          <ChatInputWrapper data-textarea-content={message}>
            <ChatInput rows={1} onKeyUp={handleTextAreaEnter} onChange={listenToTextAreaHeight} name="message" placeholder={t('chat-log.input-placeholder', "Message BrieflyAI")} value={message} />
          </ChatInputWrapper>
          <SubmitButton disabled={isLoading || message.trim().length === 0} small>
            {isLoading ?
              <ThreeDots width={20} height={20} color='white' /> : t('chat-log.submit-button', 'Submit')}
          </SubmitButton>
        </Form>
      </ChatControl>
    </ChatLogContainer>
  );
}

export default ShowChatChat;
