export const wait = async (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const waitForRefToExist = async <TObj>(ref: React.RefObject<TObj>, maxAttempts = 10, interval = 100): Promise<TObj> => {
  let attempts = 0;
  while (!ref.current && attempts < maxAttempts) {
    await wait(interval);
    attempts++;
  }
  if(!ref.current) {
    throw new Error('Ref never populated');
  }
  return ref.current;
}
