import { useState, useCallback, useEffect, useContext } from 'react';
import { apiPostStream, apiGet, apiPatch } from '../../../utils/api';
import { useParams } from 'react-router-dom';
import { Container } from './styles';
import { ChatThread, Message, Sources, ThreadMessageDelta, ThreadMessageCompleted, UpdateFields } from './types';
import { cleanMessage } from './utils';
import Chat from './Chat';
import { ChatThreadContext } from '../../../ChatThreadProvider';
import MeetingFilter from '../../Reusable/MeetingFilter';

function ShowChat(): JSX.Element {
  const [isLoadingOlderMessages, setIsLoadingOlderMessages] = useState(false);
  const [oldestMessageId, setOldestMessageId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [messages, setMessages] = useState<Message[]>([]);
  const { chat_uuid: chatId } = useParams();
  const {
    currentChatThread: chatThread,
    currentTranscripts: transcripts,
    loadChatThread,
    setChatThread,
    setAutoRenamingIds,
    isEditingMeetings,
    setIsEditingMeetings,
    setCurrentChatThreadUUID,
    updateChatThread,
  } = useContext(ChatThreadContext);

  useEffect(() => {
    setCurrentChatThreadUUID(chatId);
    return () => setCurrentChatThreadUUID(undefined);
  }, [chatId]);

  const getOlderMessages = useCallback(async (lastMessageId: string, onDone?: () => void) => {
    setIsLoadingOlderMessages(true);
    const { messages, sources } = await apiGet<Message[] | Sources, 'messages' | 'sources'>({ path: `/chat-threads/${chatId}/messages?after=${lastMessageId}` }) as { messages: Message[], sources: Sources; };

    const customMessages = messages.map((m) => cleanMessage(m, sources)).reverse();

    setMessages((m) => [...customMessages, ...m]);
    setIsLoadingOlderMessages(false);
    setOldestMessageId(lastMessageId);
    onDone && onDone();
  }, [chatId]);

  const handleMeetingsUpdate = async (transcriptIds: number[]) => {
    await updateChatThread(chatId!, transcriptIds);
    await getMessages()

    setIsEditingMeetings(false);
  };

  const getMessages = useCallback(async () => {
    setIsLoading(true);
    const { messages, sources } = await apiGet<Message[] | Sources, 'messages' | 'sources'>({ path: `/chat-threads/${chatId}/messages` }) as { messages: Message[], sources: Sources; };

    const customMessages = messages.map((m) => cleanMessage(m, sources)).reverse();

    setMessages(customMessages);
    setIsLoading(false);
  }, [chatId]);

  const init = useCallback(async (_id) => {
    setMessages([]);
    if (chatId) {
      setIsLoading(true);
      const abortController = new AbortController();
      await loadChatThread({ chatId, signal: abortController.signal });
      getMessages();

      return () => abortController.abort();
    }
  }, [chatId])

  useEffect(() => { init(chatId) }, [chatId]);


  const handleSubmit = async (body: { message: string; }): Promise<void> => {
    setIsLoading(true);
    setLoadingMessage('');

    setIsLoading(true);
    const message: Message = {
      id: body.message,
      content: [{ text: { value: body.message, annotations: [] } }],
      role: "user",
      metadata: {},
    };

    setMessages((messages) => [...messages, message]);

    await apiPostStream<{ messages: ThreadMessageDelta | ThreadMessageCompleted, sources: Sources; }>({ path: `/chat-threads/${chatId}/message`, body }, (event) => {
      const { messages: { data }, sources } = event;
      if ('delta' in data) {
        const text = cleanMessage(data.delta, sources).content.map((c) => c.text.value).join();
        return setLoadingMessage((message) => message + text);
      }
      setIsLoading(false);
      setLoadingMessage('');
      setMessages((m) => [...m, cleanMessage(data, sources)]);
    }).finally(() => {
      setIsLoading(false);
    });

    if (chatThread?.title === 'Untitled' && messages.filter(({ role }) => role === 'user').length > 1) {
      setAutoRenamingIds((ids: string[]) => [...ids, chatThread.uuid]);
      setIsLoading(true);

      const { chat_thread } = await apiPatch<UpdateFields, ChatThread, 'chat_thread'>({
        path: `/chat-threads/${chatThread!.uuid}`,
        body: { generate_title: true },
      });

      setChatThread(chat_thread);
      getMessages();
      setAutoRenamingIds((ids) => ids.filter((id) => id !== chatThread.uuid));
      setIsLoading(false);
    }
  };


  return (
  <Container>
    <MeetingFilter
      open={isEditingMeetings}
      onOpenChange={setIsEditingMeetings}
      chatConnectedMeetings={transcripts.map(({ id }) => id) || []}
      onConfirm={handleMeetingsUpdate}
      onClose={() => setIsEditingMeetings(false)}
    />
    {chatThread && (
      <Chat
        trackedOldestMessageId={oldestMessageId}
        isLoadingOlderMessages={isLoadingOlderMessages}
        onScrollOlderMessages={getOlderMessages}
        isLoading={isLoading}
        messages={messages}
        loadingMessage={loadingMessage}
        onSubmit={handleSubmit}
      />
    )
    }
  </Container >
  );
}

export default ShowChat;

