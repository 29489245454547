import styled, { css } from "styled-components";
import { AttendeeByColor } from "../MeetingCall/Types";
import uniqBy from 'lodash.uniqby';
import { Tooltip } from "react-tooltip";
import { useId } from "react";

const Container = styled.div`
  padding-left: 4px;
  position: relative;
`;

const Attendee = styled.div<{ $color: string, $size?: string; }>`
  ${({ $color, $size }) => css`
    position: relative;
    background: ${$color};
    width: ${$size || '24px'};
    height: ${$size || '24px'};
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    padding: 4px;
    margin-left: -8px;
    outline: 3px solid var(--white);
    user-select: none;
  `}

  &:hover {
    z-index: 1;
  }
`;

const TooltipListItem = styled.p`
margin: 0;
`;

interface Props {
  attendeesByColor: [AttendeeByColor] | [];
  keyId: string,
  className?: string;
  limit?: number;
  size?: string;
}


const Attendees = ({ attendeesByColor, className, limit, keyId, size }: Props) => {
  const isOverLimit = limit && limit < attendeesByColor.length;
  const uniqueAttendees = uniqBy(attendeesByColor || [], 'name');
  const attendees = uniqueAttendees.slice(0, isOverLimit ? limit : attendeesByColor.length);

  const numRemaining = limit ? attendeesByColor.length - limit : 0;
  const id = useId();
  const restParticipantsTooltipId = `rest-participants-${id}`;

  return (
    <Container className={className}>
      {
        attendees?.map((attendee) => {
          const initials = (attendee.name[0] + (attendee.name.split(' ')?.[1]?.[0] || '')).toUpperCase();
          const key = `${keyId}-${attendee.name.replace(/['"]/g, '')}`;
          return (
            <>
              <Attendee data-tooltip-id={key} key={key} $color={attendee.deviceColor} $size={size}>{initials}</Attendee>
              <Tooltip id={key} place="bottom" style={{zIndex: 9999}}>
                {attendee.name}
              </Tooltip>
            </>
          )
        })}
      {numRemaining > 0 && 
        <>
          <Attendee data-tooltip-id={restParticipantsTooltipId} $color='#B4B4B4' $size={size}>+{numRemaining}</Attendee>
          <Tooltip id={restParticipantsTooltipId} place="bottom" style={{zIndex: 9999}}>
            {attendeesByColor.slice(limit).map((a) => a.name).map((name) => <TooltipListItem key={name}>- {name}</TooltipListItem>)}
          </Tooltip>
        </>
      }
    </Container>
  );

};

export default Attendees;
