import React, { useRef } from 'react';
import { CopyIcon, EllipsisIcon } from '../../Icons';
import { ContextMenu, ContextMenuButton, ContextMenuContainer, ContextMenuItem, ItemButton } from './styles';
import { useTranslation } from 'react-i18next';
import { SavePromptDialog } from './SavePromptDialog';
import { copyToClipboard } from '../../../utils/genericUtils';

interface ChatContextMenuProps {
  buttons: React.ReactNode[];
  disabled?: boolean;
  className?: string;
  isTemplateView?: boolean;
}

export function ContextMenuWithActions({ text }: { text: string }): JSX.Element {
  const { t } = useTranslation();

  return <ChatContextMenu buttons={[
    <ItemButton key="copy-button" onClick={() => copyToClipboard(text)}>
      <CopyIcon style={{ transform: 'translateX(-10%)' }} size={16} color="var(--brand-primary)" />
      <span>{t('chat-log.message-options.copy', 'Copy message')}</span>
    </ItemButton>,
    <SavePromptDialog defaultValue={text} key="save-prompt-button" />,
  ]} />
}

export function ChatContextMenu(props: ChatContextMenuProps) {
  const { buttons, disabled, isTemplateView } = props;
  const menuRef = useRef<HTMLUListElement>(null);

  const scrollIntoMenu = () => {
    if (menuRef.current) {
      menuRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
    }
  };

  return (
    <ContextMenuContainer className={props.className}>
      <ContextMenuButton disabled={disabled} data-template-view={isTemplateView} onFocus={scrollIntoMenu}><EllipsisIcon /></ContextMenuButton>
      <ContextMenu ref={menuRef}>
        {buttons.map((button, index) => {
          return (
            <ContextMenuItem key={index}>
              {button}
            </ContextMenuItem>
          );
        }
        )}
      </ContextMenu>
    </ContextMenuContainer>
  );
}


