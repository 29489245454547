import * as RadixDialog from '@radix-ui/react-dialog';
import styled, { css } from 'styled-components';
import { XIcon } from '../Icons';
import { CSSProperties } from 'react';

const Overlay = styled(RadixDialog.Overlay)`
  background: rgba(0, 0, 0, .7);
  position: fixed;
  inset: 0;
  animation: fadeIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 999;
`;

const Content = styled(RadixDialog.Content) <{ $maxWidth?: string; }>`
  background-color: var(--white);
  color: var(--textPrimary);
  border-radius: var(--standardRadius);
  border: var(--standardBorder);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-height: 85vh;
  padding: 25px;
  animation: fadeIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 9999;

  ${({ $maxWidth }) => (css`
    max-width: ${$maxWidth || '500px'};
  `)}
`;

interface Props extends RadixDialog.DialogProps {
  description?: string,
  title?: string,
  trigger: JSX.Element,
  maxWidth?: string,
  titleStyle?: CSSProperties,
  hideClose?: boolean,
  onOpenAutoFocus?: (event: Event) => void;
  className?: string;
}


const Dialog = ({ description, onOpenChange, onOpenAutoFocus, open, trigger, title, titleStyle, maxWidth, children, hideClose, className }: Props) => {
  return (
    <RadixDialog.Root open={open} onOpenChange={onOpenChange}>
      <RadixDialog.Trigger style={{ background: 'none', border: 'none', padding: 0 }}>
        {trigger}
      </RadixDialog.Trigger>
      <RadixDialog.Portal>
        <Overlay />
        <Content className={className} $maxWidth={maxWidth} onOpenAutoFocus={onOpenAutoFocus} >
          <RadixDialog.Title style={titleStyle}>{title}</RadixDialog.Title>
          {description && (
            <RadixDialog.Description>{description}</RadixDialog.Description>
          )}
          {children}
          {!hideClose && (
            <RadixDialog.Close>
              <XIcon size={12} />
            </RadixDialog.Close>
          )}
        </Content>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
};

export default Dialog;
