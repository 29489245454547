import { useState } from "react";
import { apiPatch } from "../../../utils/api";
import { TaskProps, WorkflowRun, Props } from "./types";
import FindSalesforceObjectSelect from "./FindSalesforceObjectSelect";
import ReviewFieldMappings from "./ReviewFieldMapping";
import ConfirmChatter from "./ConfirmChatter";
import { Button } from "../../../theme";

export default function MeetingTabWorkflowRun(props: Props): JSX.Element {
  const { workflowRun: { id, currentTask }, refreshWorkflowRun } = props;
  const [isLoading, setIsLoading] = useState(false);

  const updateWorkflowRun = async (body: Record<string, unknown>) => {
    setIsLoading(true);
    return apiPatch<'', WorkflowRun, 'workflowRun'>({
      path: `/workflow_runs/${id}`,
      body,
    })
      .then(() => refreshWorkflowRun(id))
      .finally(() => setIsLoading(false));
  }

  if (currentTask.trigger === 'auto') {
    return <Button onClick={() => updateWorkflowRun({})}>Try Again</Button>
  }

  const taskProps: TaskProps = { ...props, updateWorkflowRun, isLoading }

  if (currentTask.name === 'find_salesforce_object' && currentTask.step === 'select') {
    return <FindSalesforceObjectSelect {...taskProps} />
  }

  if (currentTask.name === 'find_salesforce_object' && currentTask.step === 'review_value') {
    return <ReviewFieldMappings {...taskProps} />
  }

  if (currentTask.name === 'update_chatter_with_fields' && currentTask.step === 'confirm') {
    return <ConfirmChatter {...taskProps} />
  }

  if (currentTask.name === 'stop') {
    return <ReviewFieldMappings {...taskProps} readOnly />
  }

  throw new Error(`Unhandled workflow: ${currentTask.name} -> ${currentTask.step}`);
}
