import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ThreeDots } from "react-loader-spinner";
import { remark } from "remark";
import remarkStrip from 'strip-markdown';
import { Button } from "../../../theme";
import { apiPatch, apiPost } from "../../../utils/api";
import { waitForRefToExist } from "../../../utils/async";
import formEntriesToBody from "../../../utils/formEntriesToBody";
import { EditIcon, PlusIcon } from "../../Icons";
import Dialog from '../../Reusable/Dialog';
import OutlineButton from "../../Reusable/OutlineButton";
import { ItemButton, PromptFormButtons, PromptFormError, PromptFormInput, PromptFormLabel, PromptFormLoaderOverlay, PromptFormTextarea } from "./styles";
import { Prompt } from "./Templates";
import { SavePromptDialogProps } from "./types";

export function SavePromptDialog(props: SavePromptDialogProps) {
  const { defaultValue: defaultMarkdownValue, editingPrompt, setIsShowingEdit, onSuccess } = props;
  const isEditing = Boolean(editingPrompt);
  const [defaultPlainValue, setDefaultPlainValue] = useState<string>('');
  const [isOpen, setIsOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [promptName, setPromptName] = useState(editingPrompt?.label || '');
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const { t } = useTranslation();

  const title = isEditing ? t('chat-log.edit-prompt.title', 'Edit prompt') : t('chat-log.create-prompt.title', 'Create prompt');
  const description = isEditing ? t('chat-log.edit-prompt.description', 'Edit a prompt that you can use later on any other chat') : t('chat-log.create-prompt.description', 'Create a prompt that you can use later on any other chat');
  const trigger = isEditing ? t('chat-log.message-options.edit-prompt', 'Edit prompt') : t('chat-log.message-options.create-prompt', 'Create prompt');

  const closeModal = () => {
    setIsShowingEdit && setIsShowingEdit(false);
    setIsOpen(false);
  };

  useEffect(() => {
    const initTextArea = async () => {
      if (isOpen) {
        const currentTextArea = await waitForRefToExist(textAreaRef);
        const plainTextFile = await remark().use(remarkStrip, { remove: ['link', 'linkReference'] }).process(defaultMarkdownValue);
        const plainText = plainTextFile.toString();
        setDefaultPlainValue(plainText);
        currentTextArea.textContent = plainText || '';
      }
    };
    initTextArea();
  }, [isOpen]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    const { label, prompt: formPrompt } = formEntriesToBody(new FormData(form));
    const editingId = editingPrompt?.id || "";

    let prompt = formPrompt as string;

    if (prompt.trim() === '') {
      prompt = defaultPlainValue;
    }

    setIsSaving(true);
    const body = { label, prompt };
    isEditing ?
      apiPatch<'label' | 'prompt', Prompt, 'prompt'>({ path: `/my_saved_prompts/${editingId}`, body }).then((response) => {
        onSuccess && onSuccess(response.prompt);
        closeModal();
      }).catch(() => {
        setErrorMessage(t('chat-log.edit-prompt.generic-error', 'Failed to save edited prompt, please try again later.'));
      }).finally(() => {
        setIsSaving(false);
      }) :
      apiPost<'label' | 'prompt', Prompt, 'prompt'>({ path: '/briefly/post_briefly_my_saved_prompt', body }).then((response) => {
        onSuccess && onSuccess(response.prompt);
        closeModal();
      }).catch(() => {
        setErrorMessage(t('chat-log.create-prompt.generic-error', 'Failed to save prompt, please try again later.'));
      }).finally(() => {
        setIsSaving(false);
      });
  };

  const onOpenChange = (state: boolean) => {
    setIsShowingEdit && setIsShowingEdit(state);
    // Prevent the dialog from closing when the user is saving the prompt
    if (!state && isSaving) {
      return;
    }
    setIsOpen(state);
  };

  return (
    <Dialog
      title={title}
      description={description}
      open={isOpen}
      onOpenChange={onOpenChange}
      hideClose
      trigger={
        <ItemButton>
          {isEditing ? <EditIcon style={{ width: 16 }} color="var(--brand-primary)" /> : <PlusIcon size={16} color="var(--brand-primary)" />}
          <span>{trigger}</span>
        </ItemButton>
      }>
      {isSaving &&
        <PromptFormLoaderOverlay>
          <ThreeDots color='var(--brand-primary)' width={60} />
        </PromptFormLoaderOverlay>
      }
      <form onSubmit={onSubmit}>
        <PromptFormLabel htmlFor="label">{t('chat-log.create-prompt.prompt-name', 'Prompt Name')}</PromptFormLabel>
        <PromptFormInput placeholder={t('chat-log.create-prompt.placeholder', 'Write a name for your prompt')} value={promptName} onChange={(e) => setPromptName(e.currentTarget.value)} name="label" />
        <PromptFormLabel htmlFor="label">{t('chat-log.create-prompt.prompt-text', 'You can edit it\'s content before saving it')}</PromptFormLabel>
        <PromptFormTextarea name='prompt' ref={textAreaRef} placeholder={defaultPlainValue}></PromptFormTextarea>
        {errorMessage && <PromptFormError>{errorMessage}</PromptFormError>}
        <PromptFormButtons>
          <OutlineButton onClick={closeModal} type='button'>{t('cancel', { ns: 'Actions', defaultValue: 'Cancel' })}</OutlineButton>
          <Button disabled={promptName.trim() === ''} type='submit'>{t('save', { ns: 'Actions', defaultValue: 'Save' })}</Button>
        </PromptFormButtons>
      </form>
    </Dialog>
  );
}

