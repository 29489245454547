import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

import { AVAILABLE_LANGUAGES, LANGUAGE_AUTO_DETECT } from '../../utils/const';
import Select from "../forms/Select";
import { Button } from '../../theme';

import { WorldIcon } from "../Icons";

import styled from "styled-components";

const Container = styled.div`
  display: flex;
  gap: 6px;
`;

const Placeholder = styled.span`
    display: flex;
    align-items: center;
    gap: 5px;
`;

interface Props {
  handleGenerateSummary: (language?: string) => void,
  userIsPaid: boolean,
}

const LanguageBox = ({
  handleGenerateSummary,
  userIsPaid,
}: Props) => {
  const { t } = useTranslation();
  const autoDetectLabel = t('meeting.ia-summary.summary-language.auto-detect', LANGUAGE_AUTO_DETECT);
  const placeholder = t('meeting.ia-summary.summary-language.placeholder', 'Select Language');
  const [selectedLanguage, setSelectedLanguage] = useState(autoDetectLabel);
  const [selectedUnconfirmedLanguage, setSelectedUnconfirmedLanguage] = useState(null);

  const onLanguageChange = (option) => {
    if (!option) {
      setSelectedUnconfirmedLanguage(null);
      return;
    }
    if (!userIsPaid) return;
    if (option.label !== selectedLanguage) {
      setSelectedUnconfirmedLanguage(option.label);
    }
  };

  const LANGUAGE_DROPDOWN_ITEMS = AVAILABLE_LANGUAGES.map(lang => {
    const label = lang === LANGUAGE_AUTO_DETECT ? autoDetectLabel : lang;

    return { label };
  });

  return (
    <Container>
      <Select
        isClearable
        options={LANGUAGE_DROPDOWN_ITEMS}
        onChange={onLanguageChange}
        placeholder={<Placeholder><WorldIcon color="var(--textSecondary)" />{placeholder}</Placeholder>}
        size="large"
      />
      {selectedUnconfirmedLanguage &&
        <Button medium secondary onClick={() => {
          setSelectedUnconfirmedLanguage(null);
          setSelectedLanguage(selectedUnconfirmedLanguage);
          handleGenerateSummary(selectedUnconfirmedLanguage);
        }}>
          {t('meeting.ia-summary.summary-language.change', 'Change')
          }
        </Button >

      }
    </Container >
  );
};

export default LanguageBox;
