import { forwardRef, ReactNode, Ref } from 'react';
import { ButtonProps, WhiteButton } from '../../theme';
import styled from 'styled-components';


const Container = styled.div`
  display: flex;
  background: linear-gradient(var(--focusGradientPrimary));
  padding: 1px;
  border-radius: var(--standardRadius);
  height: fit-content;
  width: fit-content;
`;

interface Props extends ButtonProps {
  className?: string;
  bgColor?: string;
  ref?: Ref<ReactNode>;
}

const OutlineButton = forwardRef<HTMLDivElement, Props>(({ forwardedAs = "button", bgColor, className, children, ...rest }, ref) => {
  return (
    <Container ref={ref}>
      <WhiteButton forwardedAs={forwardedAs} className={className} $bgColor={bgColor} coloredOutline {...rest} >
        {children}
      </WhiteButton>
    </Container>
  );
});

export default OutlineButton;

OutlineButton.displayName = "OutlineButton";
