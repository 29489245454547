import React from 'react';
import { SvgSize } from './types';

const Pages = (props: SvgSize) => (
  <svg width={props?.size ?? '65'} height={props?.size ?? '85'} viewBox="0 0 65 85" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M61.9849 9.67188H31.9686L9.49601 76.4195V81.9034C9.49593 82.5915 9.7686 83.2509 10.2529 83.7337C10.7372 84.2165 11.3926 84.4823 12.0721 84.4716L60.992 83.7435C62.3642 83.7256 63.4727 82.6047 63.4923 81.2154L64.5247 12.3002C64.5398 11.6084 64.2789 10.9397 63.8009 10.445C63.323 9.95042 62.6683 9.67171 61.9849 9.67188Z" fill="var(--white)" />
    <path fillRule="evenodd" clipRule="evenodd" d="M61.9849 9.67188H31.9686L9.49601 76.4195V81.9034C9.49593 82.5915 9.7686 83.2509 10.2529 83.7337C10.7372 84.2165 11.3926 84.4823 12.0721 84.4716L60.992 83.7435C62.3642 83.7256 63.4727 82.6047 63.4923 81.2154L64.5247 12.3002C64.5398 11.6084 64.2789 10.9397 63.8009 10.445C63.323 9.95042 62.6683 9.67171 61.9849 9.67188Z" stroke="var(--textPrimary)" strokeWidth="0.77976" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M0.474665 4.75598V73.6377C0.474849 74.4648 0.808285 75.2559 1.39789 75.8283C1.98749 76.4007 2.78221 76.7048 3.59833 76.6702L50.493 74.6663C52.082 74.5992 53.3428 73.2875 53.3659 71.6773L54.118 16.9226L37.6618 0.527893L3.35754 1.71015C1.74302 1.77313 0.468135 3.12008 0.474665 4.75598Z" fill="var(--white)" />
    <path fillRule="evenodd" clipRule="evenodd" d="M0.474665 4.75598V73.6377C0.474849 74.4648 0.808285 75.2559 1.39789 75.8283C1.98749 76.4007 2.78221 76.7048 3.59833 76.6702L50.493 74.6663C52.082 74.5992 53.3428 73.2875 53.3659 71.6773L54.118 16.9226L37.6618 0.527893L3.35754 1.71015C1.74302 1.77313 0.468135 3.12008 0.474665 4.75598V4.75598Z" stroke="var(--textPrimary)" strokeWidth="0.77976" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M37.6519 0.541199V14.2908C37.6511 14.9926 37.926 15.6659 38.4161 16.1621C38.9062 16.6584 39.5712 16.9368 40.2643 16.9359H54.118L37.6519 0.541199Z" fill="var(--white)" />
    <path fillRule="evenodd" clipRule="evenodd" d="M37.6519 0.541199V14.2908C37.6511 14.9926 37.926 15.6659 38.4161 16.1621C38.9062 16.6584 39.5712 16.9368 40.2643 16.9359H54.118L37.6519 0.541199Z" stroke="var(--textPrimary)" strokeWidth="0.77976" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.49272 12.237L28.0071 11.5557" stroke="var(--textPrimary)" strokeWidth="0.77976" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.49272 19.6946L28.0071 19.1269" stroke="var(--textPrimary)" strokeWidth="0.77976" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.0898 27.6564L44.8591 28.2576" stroke="var(--textPrimary)" strokeWidth="0.77976" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.0898 36.0926L44.8591 36.7906" stroke="var(--textPrimary)" strokeWidth="0.77976" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.0898 45.1532H43.7937" stroke="var(--textPrimary)" strokeWidth="0.77976" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M30.0258 54.2137H43.7937" stroke="var(--textPrimary)" strokeWidth="0.77976" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M30.9758 62.3828L43.7937 61.6614" stroke="var(--textPrimary)" strokeWidth="0.77976" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Pages;

