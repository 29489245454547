import { useState, useMemo, useEffect } from 'react';
import uniqBy from 'lodash.uniqby';
import {
  groupConsecutiveByDeviceId, getTranscriptionAsText, highlightText
} from '../../utils/genericUtils';
import { MeetingData } from './Types';
import styled from 'styled-components';
import { Body } from '../../theme';
import CopyToClipboard from '../Reusable/CopyToClipboard';
import AttendeeAvatar from '../Reusable/Attendees';
import { useTranslation } from 'react-i18next';

interface Person {
  name: string,
  deviceColor: string,
}

const Container = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

const Left = styled.div`
  flex-grow: 1;
  border: var(--standardBorder);
  border-radius: var(--standardRadius);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
`;

const CopyWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;

const Person = styled.h6`
  font-weight: 700;
  font-size: 12px;
  margin: 0;
`;

const Right = styled(Left)`
  min-width: min(25%, 283px);
  max-width: min(25%, 283px);
  gap: 16px;

`;

const Attendee = styled.div`
  border: var(--standardBorder);
  border-radius: var(--standardRadius);
  background: var(--gray200);
  padding: 5px;
  padding-left: 15px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 700;
  font-size: 14px;
`;

interface Props {
  meetingData: MeetingData;
  searchWords?: [string];
}


export default function MeetingTabTranscript({ meetingData, searchWords }: Props) {
  const { t } = useTranslation();

  const { attendeesByColor, deviceNamesById, transcriptionBlocks } = meetingData;

  const groupedTranscriptionBlocks = useMemo(() => transcriptionBlocks ? groupConsecutiveByDeviceId(transcriptionBlocks) : [], [transcriptionBlocks]);

  const [scrollExecutedForMeetingIdAndSearchWords, setScrollExecutedForMeetingIdAndSearchWords] = useState<[null | string, null | [string]]>([null, null]);




  useEffect(() => {
    if (searchWords) {
      if (JSON.stringify(scrollExecutedForMeetingIdAndSearchWords) === JSON.stringify([meetingData.meetingId, searchWords])) {
        return;
      }
      setScrollExecutedForMeetingIdAndSearchWords([meetingData.meetingId, searchWords]);
      setTimeout(() => {
        const scrollTo = document.querySelector("#transcript-container .highlight");
        if (scrollTo) {
          scrollTo.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 100);
    }
  }, [meetingData.meetingId, searchWords]);

  return (
    <Container>
      <Left>
        <CopyWrapper>
          <CopyToClipboard size={18} text={getTranscriptionAsText(meetingData)} />
        </CopyWrapper>
        {groupedTranscriptionBlocks.map(({ deviceId, blocks }) => {
          const deviceName = deviceNamesById[deviceId];
          const deviceColor = attendeesByColor.find((a) => a.name === deviceName)?.deviceColor;


          return <div key={blocks[0].messageId}>
            <Person style={{ color: deviceColor }}>{deviceName}</Person>
            <Body>
              {blocks.map(x => x.text).join(' ').split('\n').map((x, i) =>
                <span key={`${x}-${i}`}>
                  {searchWords ? highlightText(x, searchWords, { background: 'var(--highlight)', color: 'black' }) : x}
                </span>)}
            </Body>
          </div>;
        })}
      </Left>
      {attendeesByColor.length > 0 &&
        <Right>
          <span style={{ fontWeight: 600, fontSize: "12px" }}>{t('meeting.transcript.speakers', 'Speakers:')}</span>
          {uniqBy(attendeesByColor, 'name').map((person: Person) => {
            return (
              <Attendee key={`transcript-${person.name}-wrapper`}>
                <AttendeeAvatar attendeesByColor={[person]} keyId="transcript" />
                {person.name}
              </Attendee>
            );
          })}
        </Right>}
    </Container>
  );
}
