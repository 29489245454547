import { ReactElement, ReactNode } from 'react';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import styled from 'styled-components';
import OutlineButton from './OutlineButton';
import { Button } from '../../theme';

const Overlay = styled(AlertDialog.Overlay)`
  background: rgba(0, 0, 0, .7);
  position: fixed;
  inset: 0;
  animation: fadeIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 99999;
`;

const Content = styled(AlertDialog.Content)`
  background-color: var(--white);
  color: var(--textPrimary);
  border-radius: var(--standardRadius);
  border: var(--standardBorder);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 500px;
  max-height: 85vh;
  padding: 25px;
  animation: fadeIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 999999;
`;

const Title = styled(AlertDialog.Title)`
  margin-top: 0;
`;


const ButtonGroup = styled.div`
  display: flex;
  gap: 26px;
  justify-content: flex-end;
`;

const Cancel = styled(AlertDialog.Cancel)`
  all: unset;
    outline-offset: 2px;
`;

const Confirm = styled(AlertDialog.AlertDialogAction)`
  all: unset;
`;

interface Props extends AlertDialog.AlertDialogProps {
  cancelContent: string | ReactElement,
  confirmContent?: string | ReactElement,
  description?: string | ReactElement,
  onConfirm: (unknown) => void,
  title: string,
  trigger?: ReactNode,
  children?: ReactNode,
}


const Alert = ({
  cancelContent,
  confirmContent,
  description,
  onOpenChange,
  onConfirm,
  open,
  trigger,
  title,
  children
}: Props) => {

  return (
    <AlertDialog.Root open={open} onOpenChange={onOpenChange}>
      {trigger && <AlertDialog.Trigger asChild>
        {trigger}
      </AlertDialog.Trigger>}
      <AlertDialog.Portal>
        <Overlay />
        <Content>
          <Title>{title}</Title>
          { description &&
            <AlertDialog.Description>
              {description}
            </AlertDialog.Description>
          }
          {children}
          <ButtonGroup>
            <Cancel>
              <OutlineButton forwardedAs="span">
                {cancelContent}
              </OutlineButton>
            </Cancel>
            {confirmContent &&
              <Confirm onClick={onConfirm} >
                <Button forwardedAs="span">
                  {confirmContent}
                </Button>
              </Confirm>
            }
          </ButtonGroup>
        </Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
};

export default Alert;