import React from 'react';
import { SvgSize } from './types';

const RedoArrowIcon = (props: SvgSize) => (
  <svg width={props?.size ?? '16'} height={props?.size ?? '16'} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_600_4421)">
      <path d="M13.654 2.33918L15.147 0.850541C15.4622 0.536269 16 0.759512 16 1.20461V5.83831C16 6.45583 15.4458 6.92577 14.8366 6.82487L10.9241 6.17691C10.5222 6.11034 10.3643 5.61679 10.653 5.32932L12.1994 3.78947C11.0108 2.60429 9.60313 2.0117 7.97654 2.0117C6.31867 2.0117 4.89541 2.60429 3.70674 3.78947C2.54936 4.94347 1.97067 6.34698 1.97067 8C1.97067 9.65302 2.54936 11.0721 3.70674 12.2573C4.89541 13.4113 6.31867 13.9883 7.97654 13.9883C9.13392 13.9883 10.26 13.5984 11.3548 12.8187C12.247 12.2009 12.9134 11.4853 13.354 10.6718C13.5618 10.2881 13.9433 10.0117 14.3796 10.0117C15.0595 10.0117 15.5609 10.655 15.2895 11.2785C14.7732 12.4649 13.9778 13.4773 12.9032 14.3158C11.4643 15.4386 9.82209 16 7.97654 16C5.7869 16 3.91007 15.2203 2.34604 13.6608C0.782014 12.1014 0 10.2144 0 8C0 5.78557 0.782014 3.89864 2.34604 2.33918C3.91007 0.779727 5.7869 0 7.97654 0C8.91496 0 9.93157 0.233918 11.0264 0.701754C12.1525 1.1384 13.0283 1.68421 13.654 2.33918Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_600_4421">
        <rect fill="currentColor" width='16' height='16' />
      </clipPath>
    </defs>
  </svg>
);

export default RedoArrowIcon;
