import { useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import Dialog from '../../Reusable/Dialog';
import AsyncSearchSelect, { ServerOption, Option } from '../../forms/AsyncSearchSelect';
import { SingleValue } from "react-select";
import { SalesforceSearchObject, TaskProps } from "./types";
import { Button, FlexColumn10, FlexEnd10, Body } from "../../../theme";
import { useTranslation } from "react-i18next";
import { apiGet } from "../../../utils/api";

export function SearchObjectDialog(props: TaskProps): JSX.Element {
  const { t } = useTranslation();
  const { objectType } = props.workflowRun;

  const [isContactDialogOpen, setIsContactDialogOpen] = useState(false);

  return (
    <Dialog
      trigger={<Button forwardedAs="span" link><Body>Change</Body></Button>}
      open={isContactDialogOpen}
      onOpenChange={setIsContactDialogOpen}
      maxWidth="800px"
      title={t('workflow_run.find-salesforce-object.search.placeholder', `Search Salesforce ${objectType}s`, { objectType, count: 2 })}
      titleStyle={{ fontSize: '18px', marginTop: 0 }}
      hideClose
    >
      <FlexColumn10>
        <SearchObject {...props} onDone={() => setIsContactDialogOpen(false)} />
      </FlexColumn10>
    </Dialog>
  )
}


export default function SearchObject({ workflowRun, updateWorkflowRun, isLoading, onDone }: TaskProps & { onDone?: () => void }): JSX.Element {
  const { t } = useTranslation();
  const { objectType } = workflowRun;

  const [object, setObject] = useState<SingleValue<Option>>()
  const handleSearch = async (q: string): Promise<{ options: ServerOption[] }> => {
    const { records } = await apiGet<SalesforceSearchObject[], 'records'>({
      path: `/services/salesforce/${objectType}?q=${q}`,
    });

    const options: ServerOption[] = records.map((record) => ({
      text: {
        type: "plain_text",
        text: record.Name,
      },
      value: record.Id,
    }))

    return { options };
  }

  const handleSubmit = async () => {
    if (object == null) throw new Error("Submit without object error");

    await updateWorkflowRun({
      object_id: "",
      object_name: "",
      object: { Id: object.value, state: "complete" },
    })

    onDone && onDone()
  }

  return (
    <>
      <AsyncSearchSelect
        onSearch={handleSearch}
        onChange={(option) => setObject(option)}
        placeholder={t("workflow_run.find-salesforce-object.search.placeholder", `Search for a ${objectType}`, { objectType })} />
      <FlexEnd10>
        <Button onClick={onDone} outline>Cancel</Button>
        <Button disabled={object == null || isLoading} onClick={handleSubmit}>
          {isLoading ? <ThreeDots height={18} width={22} color="white" /> : `Select ${objectType}`}
        </Button>
      </FlexEnd10>
    </>
  )
}


