import React, { Dispatch, createContext, SetStateAction, useEffect, useState, useMemo } from "react";
import { apiGet, apiPatch } from "./utils/api";
import { ChatThread } from "./components/Chat/ShowChat/types";
import { Meeting } from "./components/Reusable/MeetingFilter/types";

interface LoadArgs {
  chatId: string,
  signal?: AbortSignal,
}

type LoadThreadsCallback = (newThreads: ChatThread[]) => void;

type RenamingCallback = (ids: string[]) => string[];

interface ChatThreadContextData {
  currentChatThread: ChatThread | undefined,
  currentTranscripts: Meeting[],
  chatThreads: ChatThread[],
  transcriptsById: Record<string, Meeting[]>,
  loadChatThread: (args: LoadArgs) => Promise<void>,
  setChatThread: (chatThread: ChatThread) => void,
  loadChatThreads: (signal: AbortSignal, onFetched?: LoadThreadsCallback) => Promise<void>,
  autoRenamingIds: string[],
  setAutoRenamingIds: (callback: RenamingCallback) => void,
  isEditingMeetings: boolean,
  setIsEditingMeetings: Dispatch<SetStateAction<boolean>>,
  setCurrentChatThreadUUID: Dispatch<SetStateAction<string | undefined>>,
  updateChatThread: (uuid: string, transcriptIds: number[]) => Promise<void>,

}

const noop = (_: unknown) => Promise.resolve();

export const ChatThreadContext = createContext<ChatThreadContextData>({
  currentChatThread: undefined,
  currentTranscripts: [],
  chatThreads: [],
  loadChatThread: noop,
  transcriptsById: {},
  setChatThread: noop,
  loadChatThreads: noop,
  autoRenamingIds: [],
  setAutoRenamingIds: () => { },
  isEditingMeetings: false,
  setIsEditingMeetings: noop,
  setCurrentChatThreadUUID: noop,
  updateChatThread: noop,
});

function ChatThreadProvider({ children }: { children: JSX.Element[] }): JSX.Element {
  const [isEditingMeetings, setIsEditingMeetings] = useState(false);
  const [chatThreads, setChatThreads] = useState<ChatThread[]>([]);
  const [autoRenamingIds, setAutoRenamingIds] = useState<string[]>([]);
  const [transcriptsById, setTranscriptsById] = useState<Record<string, Meeting[]>>({});
  const [currentChatThreadUUID, setCurrentChatThreadUUID] = useState<string>();

  const setChatThread = (chatThread: ChatThread) => {
    let found = false;
    const threads = chatThreads.map((ct) => {
      if (ct.uuid !== chatThread.uuid) {
        return ct;
      }

      found = true
      return chatThread;
    })

    if (!found) threads.push(chatThread);

    setChatThreads(threads);
  }

  const loadChatThread = async ({ chatId, signal }: LoadArgs) => {
    const { chat_thread: chatThread, transcripts } = await apiGet<ChatThread | Meeting[], 'chat_thread' | 'transcripts'>({
      path: `/chat-threads/${chatId}`,
      signal
    }) as { chat_thread: ChatThread, transcripts: Meeting[] };

    setChatThread(chatThread);
    setTranscriptsById((current) => ({ ...current, [chatId]: transcripts }));
  }

  const loadChatThreads = async (signal: AbortSignal, onFetched?: LoadThreadsCallback) => {
    const { chat_threads } = await apiGet<ChatThread[], 'chat_threads'>({ path: '/chat-threads', signal })
    setChatThreads(chat_threads);
    if (onFetched) {
      // Give time to context to spread
      setTimeout(() => {
        onFetched(chat_threads);
      }, 200)
    }
  };

  const updateChatThread = async (uuid: string, transcriptIds: number[]): Promise<void> => {
    const { chat_thread: chatThread, transcripts } = await apiPatch<'transcriptIds', ChatThread | Meeting[], 'chat_thread' | 'transcripts'>({
      path: `/chat-threads/${uuid}`,
      body: { transcript_ids: transcriptIds },
    }) as { chat_thread: ChatThread, transcripts: Meeting[] };

    setChatThread(chatThread);
    setTranscriptsById((current) => ({ ...current, [uuid]: transcripts }));
  };


  useEffect(() => {
    const abortController = new AbortController()

    loadChatThreads(abortController.signal)

    return () => abortController.abort();
  }, []);

  const currentChatThread = useMemo<ChatThread | undefined>(() => {
    if (currentChatThreadUUID == null) return;

    return chatThreads.find((ct) => ct.uuid === currentChatThreadUUID);
  }, [currentChatThreadUUID, chatThreads]);

  const currentTranscripts: Meeting[] = (currentChatThreadUUID ? transcriptsById[currentChatThreadUUID] : []) || [];

  return <ChatThreadContext.Provider value={{
    currentChatThread,
    currentTranscripts,
    chatThreads,
    loadChatThread,
    loadChatThreads,
    transcriptsById,
    setChatThread,
    autoRenamingIds,
    setAutoRenamingIds,
    isEditingMeetings,
    setIsEditingMeetings,
    setCurrentChatThreadUUID,
    updateChatThread,
  }}>
    {children}
  </ChatThreadContext.Provider>;
};

export default ChatThreadProvider;
