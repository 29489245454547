import { authCalendar, trackEvent } from "../utils/genericUtils";
import { useLoaderData, useNavigate, useSearchParams } from "react-router-dom";
import useUserData from "./hooks/useUserData";
import React, { useState } from "react";
import Checkmark from "../images/checkmark.svg";

import ZoomOnboardingPopup from "./ZoomOnboardingPopup";
import SlackOnboardingPopup from "./SlackOnboardingPopup";
import HubspotOnboardingPopup from "./HubSpotOnboardingPopup";
import SalesForceOnboardingPopup from "./SalesForceOnboardingPopup";
import { useGoogleLogin } from "@react-oauth/google";
import TopNav from "./TopNav";
import { GoogleCalendarLogo, GoogleMeetLogo, HubspotLogo, SalesforceLogo, SlackLogo, ZoomLogo } from "./Icons";
import { useTranslation } from "react-i18next";
import { Button, HR } from "../theme";

const OnboardingConnecting = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const apps = searchParams.get('usingApps').split(',');
  const useSlack = searchParams.get('usingSlack') === 'true';
  const crmApps = searchParams.get('usingCRMs').split(',');
  const [userData, forceRenewUserData] = useUserData();
  const [showZoomOnboardingPopup, setShowZoomOnboardingPopup] = useState(false);
  const [showSlackOnboardingPopup, setShowSlackOnboardingPopup] = useState(false);
  const [showHubSpotOnboardingPopup, setShowHubSpotOnboardingPopup] = useState(false);
  const [showSalesforceOnboardingPopup, setShowSalesforceOnboardingPopup] = useState(false);

  const { brieflyIsInstalled, } = useLoaderData();
  const {
    is_integration_active_google_calendar,
    is_integration_active_salesforce,
    slack_workspace_name,
    salesforce_redshift_db_name,
    email_google_calendar,
    is_integration_active_hubspot,
    zoom_is_setup
  } = userData;
  const salesforceDomain = salesforce_redshift_db_name ? salesforce_redshift_db_name?.replace('staging-salesforce-', '')?.replace('prod-salesforce-', '')?.replaceAll('-', '.') : '';


  const handleRedirect = () => {
    navigate('/onboarding/finalize');
  };

  function onCalendarLoginSuccess(data) {
    authCalendar(data.code, calendarData => {
      setTimeout(() => {
        forceRenewUserData();
      }, 500);
    });
  }

  const calendarLogin = useGoogleLogin({
    onSuccess: codeResponse => onCalendarLoginSuccess(codeResponse),
    flow: 'auth-code',
    scope: "openid profile email https://www.googleapis.com/auth/calendar.readonly"
  });
  const handleActiveCalendar = () => {
    trackEvent('briefly-connect-calendar-clicked', {});
    calendarLogin();
  };

  const handleInstallExtension = () => {
    window.open('https://chrome.google.com/webstore/detail/briefly-ai-meeting-summar/bjmgcelbpkgmofiogkmleblcmecflldk', '_blank');
  };

  return (
    <div style={{
      minHeight: '100vh',
      width: '100%',
      position: 'relative'

    }}>
      <TopNav />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: 'column',
          maxWidth: 600,
          width: '100%',
          margin: 'auto',
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        }}>
        <a href={'/onboarding'} style={{
          width: '100%',
          textDecoration: 'none',
          marginBottom: 24,
          fontSize: 14,

          fontWeight: '500',
          letterSpacing: 0.15,
          wordWrap: 'break-word',
          display: 'flex',
          alignItems: 'center',
          gap: 8,
        }}>
          {t('back', { ns: 'Actions', defaultValue: 'Back' })}
        </a>

        <div style={{
          padding: 24,
          background: 'var(--backgroundSecondary)',
          borderRadius: 4,
          border: 'var(--standardBorder)',
          gap: 16,
          display: 'flex',
          width: '100%',
          boxSizing: 'border-box',
          flexDirection: 'column',
        }}>
          <div>
            <p style={{
              width: '100%',
              fontSize: 20,
              margin: 0,
              marginBottom: 10,
              fontWeight: '900',
              wordWrap: 'break-word'
            }}>{t('onboarding.connections.title', 'Meeting App integrations')}
            </p>
          </div>
          {/* GOOGLE MEET*/}
          {apps.find(e => e === 'Google Meet') &&
            <>
              <div style={{
                display: 'flex',
                alignItems: "center",
              }}>
                <div style={{
                  display: "flex",
                  gap: 8,
                  alignItems: "center",
                  flex: 1
                }}>
                  <GoogleMeetLogo width="24" />
                  <div style={{ flex: 1, gap: 8, flexDirection: 'column', display: 'flex' }}>
                    <p style={{
                      fontSize: 16,
                      fontWeight: '500',
                      letterSpacing: 0.15,
                      margin: 0,
                      wordWrap: 'break-word'
                    }}>{t('onboarding.connections.g-meet.label', 'Briefly for Google Meet')}
                      {brieflyIsInstalled &&
                        <span style={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          gap: 2,
                          marginLeft: 8,
                          fontSize: 12,

                          fontWeight: '400',
                          letterSpacing: 0.30,
                          wordWrap: 'break-word'
                        }}>
                          <img src={Checkmark} style={{ width: 12, height: 12 }} />
                          email@domain.com
                        </span>}
                    </p>
                    <p style={{
                      width: '100%',
                      color: 'var(--textSecondary)',
                      fontSize: 12,
                      fontWeight: '400',
                      margin: 0,
                      letterSpacing: 0.30,
                      wordWrap: 'break-word'
                    }}>{t('onboarding.connections.g-meet.description', 'Download the Chrome Extension to use Briefly in Google Meet')}
                    </p>
                  </div>
                </div>
                {
                  brieflyIsInstalled ?
                    <p style={{
                      textAlign: 'right',
                      fontSize: 14,
                      fontWeight: '500',
                      letterSpacing: 0.15,
                      wordWrap: 'break-word',
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: 8,
                    }}>
                      {t('onboarding.connections.g-meet.installed', 'Extension Installed')}
                      <img src={Checkmark} style={{ width: 16, height: 16 }} />
                    </p>
                    :
                    <Button
                      onClick={handleInstallExtension}
                      style={{
                        height: 'fit-content',
                        padding: '8px 16px',
                        width: 'fit-content', textWrap: 'nowrap',
                      }}>
                      {t('onboarding.connections.g-meet.add', 'Add to Chrome')}
                    </Button>}
              </div>
              <HR />
            </>}
          {/* ZOOM */}
          {apps.find(e => e === 'Zoom') && <div style={{
            display: 'flex',
            alignItems: "center",
          }}>
            <div style={{
              display: "flex",
              gap: 8,
              alignItems: "center",
              flex: 1
            }}>
              <ZoomLogo size="24" />
              <div style={{ flex: 1, gap: 8, flexDirection: 'column', display: 'flex' }}>
                <p style={{
                  fontSize: 16,

                  fontWeight: '500',
                  letterSpacing: 0.15,
                  margin: 0,
                  wordWrap: 'break-word'
                }}>{t('onboarding.connections.zoom.label', 'Briefly for Zoom')}
                </p>
                <p style={{
                  width: '100%',
                  color: 'var(--textSecondary)',
                  fontSize: 12,
                  fontWeight: '400',
                  margin: 0,
                  letterSpacing: 0.30,
                  wordWrap: 'break-word'
                }}>{t('onboarding.connections.zoom.description', 'Set up Briefly note-taker for Zoom')}
                </p>
              </div>
            </div>
            {zoom_is_setup ?
              <p style={{
                textAlign: 'right',
                fontSize: 14,
                fontWeight: '500',
                letterSpacing: 0.15,
                wordWrap: 'break-word',
                display: 'inline-flex',
                alignItems: 'center',
                gap: 8,
              }}>
                {t('onboarding.connections.zoom.connected', 'Connected')}
                <img src={Checkmark} style={{ width: 16, height: 16 }} />
              </p>
              : <Button
                onClick={() => {
                  setShowZoomOnboardingPopup(true);

                }}
              >
                {t('onboarding.connections.zoom.setup', 'Setup')}
              </Button>}
          </div>}
          {/*TOOLS */}
          <div>
            <p style={{
              width: '100%',
              fontSize: 20,
              margin: 0,
              marginBottom: 10,
              fontWeight: '900',

              wordWrap: 'break-word'
            }}>{t('onboarding.tools.title', 'Connect your tools')}
            </p>
          </div>
          {/* GOOGLE CALENDER*/}
          <div style={{

            display: "flex",
            alignItems: "center",
          }}>
            <div style={{
              display: "flex",
              gap: 8,
              alignItems: "center",
              flex: 1
            }}>
              <GoogleCalendarLogo size="24" />
              <div style={{ flex: 1, gap: 8, flexDirection: 'column', display: 'flex' }}>
                <p style={{
                  fontSize: 16,
                  fontWeight: '500',
                  letterSpacing: 0.15,
                  margin: 0,
                  wordWrap: 'break-word'
                }}>{t('onboarding.tools.g-calendar.title', 'Connect Google Calendar')}
                  {is_integration_active_google_calendar &&
                    <span style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: 2,
                      marginLeft: 8,
                      fontSize: 12,
                      fontWeight: '400',
                      letterSpacing: 0.30,
                      wordWrap: 'break-word'
                    }}>
                      <img src={Checkmark} style={{ width: 12, height: 12 }} />
                      {email_google_calendar}
                    </span>}
                </p>
                <p style={{
                  width: '100%',
                  color: 'var(--textSecondary)',
                  fontSize: 12,

                  fontWeight: '400',
                  margin: 0,
                  letterSpacing: 0.30,
                  wordWrap: 'break-word'
                }}>{t('onboarding.tools.g-calendar.description', 'Improve your Briefly experience with call data')}
                </p>
              </div>
            </div>
            {is_integration_active_google_calendar ?
              <p style={{
                textAlign: 'right',

                fontSize: 14,

                fontWeight: '500',
                letterSpacing: 0.15,
                wordWrap: 'break-word',
                display: 'inline-flex',
                alignItems: 'center',
                gap: 8,
              }}>
                {t('onboarding.tools.g-calendar.connected', 'Connected')}
                <img src={Checkmark} style={{ width: 16, height: 16 }} />
              </p>
              :
              <Button
                onClick={handleActiveCalendar}
              >
                {t('onboarding.tools.g-calendar.connect', 'Connect')}
              </Button>}
          </div>
          {
            useSlack && <>
              <HR />
              {/* SLACK */}
              <div style={{
                display: "flex",
                alignItems: "center",
              }}>
                <div style={{
                  display: "flex",
                  gap: 8,
                  alignItems: "center",
                  flex: 1
                }}>
                  <SlackLogo width="24" />
                  <div style={{ flex: 1, gap: 8, flexDirection: 'column', display: 'flex' }}>
                    <div style={{
                      display: 'flex',
                      gap: 8,
                      flexDirection: 'row',
                      alignItems: 'center',
                      textWrap: 'nowrap'
                    }}>
                      <p style={{

                        fontSize: 16,

                        fontWeight: '500',
                        letterSpacing: 0.15,
                        margin: 0,
                        wordWrap: 'break-word'
                      }}>{t('onboarding.tools.slack.title', 'Connect Slack')}
                      </p>
                      {
                        slack_workspace_name ?
                          <span style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            gap: 2,
                            marginLeft: 8,
                            color: '#fff',
                            fontSize: 12,

                            fontWeight: '400',
                            letterSpacing: 0.30,
                            wordWrap: 'break-word'
                          }}>
                            <img src={Checkmark} style={{ width: 12, height: 12 }} />
                            {slack_workspace_name}
                          </span>
                          :
                          <div style={{
                            width: 'fit-content',
                            height: '100%',
                            paddingLeft: 8,
                            paddingRight: 8,
                            paddingTop: 2,
                            paddingBottom: 2,
                            background: '#F97238',
                            borderRadius: 4,
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: 3,
                            display: 'inline-flex'
                          }}>
                            <div style={{

                              fontSize: 12,

                              fontWeight: '700',
                              letterSpacing: 0.30,
                              wordWrap: 'break-word'
                            }}>PRO
                            </div>
                          </div>}
                    </div>
                    <p style={{
                      width: '100%',
                      color: 'var(--textSecondary)',
                      fontSize: 12,
                      fontWeight: '400',
                      margin: 0,
                      letterSpacing: 0.30,
                      wordWrap: 'break-word'
                    }}>{t('onboarding.tools.slack.description', 'Receive AI summaries and account updates in Slack')}
                    </p>
                  </div>
                </div>
                {slack_workspace_name ?
                  <p style={{
                    textAlign: 'right',

                    fontSize: 14,
                    fontWeight: '500',
                    letterSpacing: 0.15,
                    wordWrap: 'break-word',
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: 8,
                  }}>
                    {t('onboarding.tools.slack.connected', 'Connected')}
                    <img src={Checkmark} style={{ width: 16, height: 16 }} />
                  </p>
                  :
                  <Button
                    onClick={
                      () => {
                        setShowSlackOnboardingPopup(true);
                      }
                    }
                  >
                    {t('onboarding.tools.slack.connect', 'Connect')}
                  </Button>}
              </div>
            </>}
          {/* Salesforce */}
          {
            crmApps.find(e => e === 'Salesforce') && <>
              <HR />
              <div style={{
                display: "flex",
                alignItems: "center",

              }}>
                <div style={{
                  display: "flex",
                  gap: 8,
                  alignItems: "center",
                  flex: 1
                }}>
                  <SalesforceLogo style={{ width: 24, height: 24 }} />
                  <div style={{ flex: 1, gap: 8, flexDirection: 'column', display: 'flex' }}>
                    <div style={{
                      display: 'flex',
                      gap: 8,
                      flexDirection: 'row',
                      alignItems: 'center',
                      textWrap: 'nowrap'
                    }}>
                      <p style={{

                        fontSize: 16,

                        fontWeight: '500',
                        letterSpacing: 0.15,
                        margin: 0,
                        wordWrap: 'break-word'
                      }}>{t('onboarding.tools.salesforce.title', 'Connect Salesforce')}
                      </p>
                      {is_integration_active_salesforce ?
                        <span style={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          gap: 2,
                          marginLeft: 8,
                          color: '#fff',
                          fontSize: 12,

                          fontWeight: '400',
                          letterSpacing: 0.30,
                          wordWrap: 'break-word'
                        }}>
                          <img src={Checkmark} style={{ width: 12, height: 12 }} />
                          {salesforceDomain}
                        </span>
                        :
                        <div style={{
                          width: 'fit-content',
                          height: '100%',
                          paddingLeft: 8,
                          paddingRight: 8,
                          paddingTop: 2,
                          paddingBottom: 2,
                          background: '#F97238',
                          borderRadius: 4,
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          gap: 3,
                          display: 'inline-flex'
                        }}>
                          <div style={{

                            fontSize: 12,

                            fontWeight: '700',
                            letterSpacing: 0.30,
                            wordWrap: 'break-word'
                          }}>PRO
                          </div>
                        </div>
                      }
                    </div>
                    <p style={{
                      width: '100%',
                      color: 'var(--textSecondary)',
                      fontSize: 12,
                      fontWeight: '400',
                      margin: 0,
                      letterSpacing: 0.30,
                      wordWrap: 'break-word'
                    }}>{t('onboarding.tools.salesforce.description', 'Save transcripts and AI summaries to Salesforce')}
                    </p>
                  </div>
                </div>
                {
                  is_integration_active_salesforce ?
                    <p style={{
                      textAlign: 'right',

                      fontSize: 14,
                      fontWeight: '500',
                      letterSpacing: 0.15,
                      wordWrap: 'break-word',
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: 8,
                    }}>
                      {t('onboarding.tools.salesforce.connected', 'Connected')}
                      <img src={Checkmark} style={{ width: 16, height: 16 }} />
                    </p>
                    : <Button
                      onClick={
                        () => {
                          setShowSalesforceOnboardingPopup(true);
                        }
                      }
                    >
                      {t('onboarding.tools.salesforce.connect', 'Connect')}
                    </Button>
                }
              </div>
            </>
          }
          {/* Hubspot */}
          {
            crmApps.find(e => e === 'Hubspot') && <>
              <HR />
              <div style={{
                display: "flex",
                alignItems: "center",

              }}>
                <div style={{
                  display: "flex",
                  gap: 8,
                  alignItems: "center",
                  flex: 1
                }}>
                  <HubspotLogo size="24" />
                  <div style={{ flex: 1, gap: 8, flexDirection: 'column', display: 'flex' }}>
                    <div style={{
                      display: 'flex',
                      gap: 8,
                      flexDirection: 'row',
                      alignItems: 'center',
                      textWrap: 'nowrap'
                    }}>
                      <p style={{

                        fontSize: 16,

                        fontWeight: '500',
                        letterSpacing: 0.15,
                        margin: 0,
                        wordWrap: 'break-word'
                      }}>{t('onboarding.tools.hubspot.title', 'Connect Hubspot')}
                      </p>
                      {is_integration_active_hubspot ?
                        <span style={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          gap: 2,
                          marginLeft: 8,
                          color: '#fff',
                          fontSize: 12,

                          fontWeight: '400',
                          letterSpacing: 0.30,
                          wordWrap: 'break-word'
                        }}>
                          <img src={Checkmark} style={{ width: 12, height: 12 }} />
                          {salesforceDomain}
                        </span>
                        :
                        <div style={{
                          width: 'fit-content',
                          height: '100%',
                          paddingLeft: 8,
                          paddingRight: 8,
                          paddingTop: 2,
                          paddingBottom: 2,
                          background: '#F97238',
                          borderRadius: 4,
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          gap: 3,
                          display: 'inline-flex'
                        }}>
                          <div style={{

                            fontSize: 12,

                            fontWeight: '700',
                            letterSpacing: 0.30,
                            wordWrap: 'break-word'
                          }}>PRO
                          </div>
                        </div>
                      }
                    </div>
                    <p style={{
                      width: '100%',
                      color: 'var(--textSecondary)',
                      fontSize: 12,
                      fontWeight: '400',
                      margin: 0,
                      letterSpacing: 0.30,
                      wordWrap: 'break-word'
                    }}>{t('onboarding.tools.hubspot.description', 'Save transcripts and AI summaries to Hubspot')}
                    </p>
                  </div>
                </div>
                {
                  is_integration_active_hubspot ?
                    <p style={{
                      textAlign: 'right',

                      fontSize: 14,
                      fontWeight: '500',
                      letterSpacing: 0.15,
                      wordWrap: 'break-word',
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: 8,
                    }}>
                      {t('onboarding.tools.hubspot.connected', 'Connected')}
                      <img src={Checkmark} style={{ width: 16, height: 16 }} />
                    </p>
                    : <Button
                      onClick={
                        () => {
                          setShowHubSpotOnboardingPopup(true);
                        }
                      }
                      style={{
                        height: 'fit-content',
                        padding: '8px 32px',
                        margin: 0,
                        width: 'fit-content', textWrap: 'nowrap',
                      }}>
                      {t('onboarding.tools.hubspot.connected', 'Connect')}
                    </Button>
                }
              </div>
            </>
          }
        </div>
        <Button style={{ marginTop: "16px" }} onClick={handleRedirect}>
          {t('onboarding.continue-to-settings', 'Continue to Briefly settings')}
        </Button>
      </div>
      {
        showZoomOnboardingPopup &&
        <ZoomOnboardingPopup
          onClose={() => setShowZoomOnboardingPopup(false)}
        />
      }
      {
        showSlackOnboardingPopup &&
        <SlackOnboardingPopup
          onClose={() => setShowSlackOnboardingPopup(false)}
        />
      }
      {
        showSalesforceOnboardingPopup &&
        <SalesForceOnboardingPopup
          onClose={() => setShowSalesforceOnboardingPopup(false)}
        />
      }
      {
        showHubSpotOnboardingPopup &&
        <HubspotOnboardingPopup
          onClose={() => setShowHubSpotOnboardingPopup(false)}
        />

      }
    </div >
  );
};


export default OnboardingConnecting;

