import * as RadixDialog from '@radix-ui/react-dialog';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDataFromEndpoint } from '../../hooks/useDataFromEndpoint';

import { Body, Button } from '../../../theme';
import { EyeIcon, TrashCanIcon } from '../../Icons';
import OutlineButton from '../../Reusable/OutlineButton';
import { ItemButton } from './styles';

import styled, { css } from 'styled-components';
import { apiDelete } from '../../../utils/api';
import { ChatContextMenu } from './ChatContextMenu';
import { SavePromptDialog } from './SavePromptDialog';
import { PromptPreviewModal } from './TemplatePreview';

export interface Prompt {
  id: number,
  label: string,
  prompt: string,
  show_in_bottom_panel: boolean,
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 1rem;
`;

const Toggles = styled.div`
  padding: 5px;
  border: var(--standardBorder);
  border-radius: var(--standardRadius);
  display: flex;
  gap: 24px;
`;

const EmptyContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;

  & > p {
    max-width: 60ch;
    text-align: center;
    color: var(--textSecondary);
  }
`;

const Content = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  list-style-type: none;
  gap: 16px 24px;
  padding: 2px;
  overflow-y: auto;
  height: 40vh;
  align-content: baseline;

  li:nth-child(even) {
    ul {
      right: 1.2rem;
      left: auto;
    }
  }
`;

const PromptTitle = styled.label`
  font-weight: 600;
  cursor: pointer;
`;

const PromptText = styled.p`
  font-size: 12px;
  color: var(--textSecondary);
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  height: 2.4rem;
  overflow: hidden;
  -webkit-box-orient: vertical;
`;

const FloatingButton = styled.button`
  position: relative;
  top: 0;
  left: 0;
  aspect-ratio: 1;
  border-radius: 99rem;
  background: none;
  border: none;
  color: var(--brand-primary);
  width: 32px;

  &:hover {
    background: var(--gray300);
  }

  &.preview-button > svg {
    transform: translateY(8%);
  }
`;

const ItemContainer = styled.li<{ $isChecked?: boolean; $isDeleted?: boolean; }>`
  border: var(--standardBorder);
  border-radius: var(--standardRadius);
  cursor: pointer;
  display: flex;
  align-items: stretch;
  height: fit-content;

  &:focus-within {
    outline: 1px solid var(--brand-secondary);
  }

  &:hover {
    background: linear-gradient(var(--focusGradientSecondary));
    color: var(--textPrimary);
  }

  ${({ $isChecked }) => $isChecked ? (css`
    background: linear-gradient(var(--focusGradientPrimary));
    color: var(--white);

    &:hover {
      background: linear-gradient(var(--focusGradientPrimary));
      color: var(--white);
    }

    & ${PromptText}, & ${FloatingButton}:not(:hover) {
      color: var(--white);
    }

    `) : (css`
    background: var(--white);
    color: var(--textPrimary);
  `)}

  ${({ $isDeleted }) => $isDeleted && (css`
    background: var(--gray400);
    color: var(--textSecondary);
    opacity: .7;
    cursor: not-allowed;
    &:hover {
      background: var(--gray400);
      color: var(--textSecondary);
      opacity: .7;
    }
    `)}
`;

const Item = styled.label<{ $isDeleted?: boolean; }>`
  width: 100%;
  cursor: pointer;
  padding: 12px;
  ${({ $isDeleted }) => $isDeleted && (css`
    cursor: not-allowed;
    `)}
`;

const FloatingButtons = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const Input = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
`;

const ContextMenu = styled(ChatContextMenu) <{ $isChecked?: boolean; $visible: boolean; }>`
  visibility: ${({ $visible }) => $visible ? `visible` : `hidden`};
  top: 0;
  color: var(--textPrimary);

  > button {
    &:hover {
      background: var(--gray300);
    }
  }

  ${({ $isChecked }) => $isChecked && (css`
    > button {
      svg {
        color: var(--gray300);

        &:hover {
          color: var(--brand-primary);
        }
      }
    }
  `)}
`;


const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;

const Cancel = styled(RadixDialog.Close)`
  all: unset;
  outline-offset: 2px;
`;


interface Props {
  onComplete: (prompt?: Prompt) => void,
}
const Templates = ({ onComplete }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [previewIndex, setPreviewIndex] = useState<number | null>(null);


  const handlePreviewChange = (state: boolean) => {
    if (state === false) {
      setPreviewIndex(null);
    }
  }

  const [selectedTab, setSelectedTab] = useState<'saved' | 'briefly'>('briefly');
  const [selectedPrompt, setSelectedPrompt] = useState<Prompt>();
  const [isShowingEdit, setIsShowingEdit] = useState(false);
  const [recommendedPromptsResponse = {}] = useDataFromEndpoint(
    '/api/briefly/briefly_recommended_prompts'
  );
  const [myPromptsResponse = {}, _, __, refreshMyPrompts] = useDataFromEndpoint(
    '/api/briefly/get_briefly_my_saved_prompts'
  );

  const handleDelete = ({ id }: { id: number }) => {
    apiDelete(`/my_saved_prompts/${id}`).then(() => {
      refreshMyPrompts()
    }).finally(() => {
      selectedPrompt?.id === id && setSelectedPrompt(undefined);
    });
  };

  const prompts = useMemo<Prompt[]>(() => {
    const currentPrompts = selectedTab === 'briefly' ? recommendedPromptsResponse?.prompts : myPromptsResponse?.prompts;
    return (currentPrompts || []).filter(({ show_in_bottom_bar }) => !show_in_bottom_bar);
  }, [selectedTab, recommendedPromptsResponse?.prompts, myPromptsResponse?.prompts]);

  const previewedPrompt = useMemo(() => {
    return prompts.find(({ id }) => id === previewIndex);
  }, [previewIndex]);

  useEffect(() => {
    setSelectedPrompt(undefined);
  }, [selectedTab]);


  return (
    <Container data-visible={!isShowingEdit}>
      <PromptPreviewModal onOpenChange={handlePreviewChange} promptName={previewedPrompt?.label} promptContent={previewedPrompt?.prompt} />
      <Body style={{ maxWidth: "311px" }}>
        {t('meeting-templates.subtitle', 'Choose from various prompts to streamline your content creation.')}
      </Body>
      <Toggles>
        <Button transparent={selectedTab === 'briefly'} full medium onClick={() => setSelectedTab('saved')}>
          {t('meeting-templates.saved-title', 'My Saved Prompts')}
        </Button>
        <Button transparent={selectedTab === 'saved'} full medium onClick={() => setSelectedTab('briefly')}>
          {t('meeting-templates.briefly-title', 'Briefly Proposed Prompts')}
        </Button>
      </Toggles>
      {prompts.length === 0 && <EmptyContent>
        <p>{t('meeting-templates.empty-list', 'You have no saved prompts. You can create a new prompt by using the chat messages.')}</p>
      </EmptyContent>}
      {prompts.length > 0 && <Content>
        {prompts.map((prompt) => {
          return <PromptItem
            key={prompt.id}
            prompt={prompt}
            checked={selectedPrompt?.id === prompt.id}
            onCheckedChange={(checked) => setSelectedPrompt(checked ? prompt : undefined)}
            onPreview={() => setPreviewIndex(prompt.id)}
            isEditable={selectedTab === 'saved'}
            handleDelete={handleDelete}
            refreshMyPrompts={refreshMyPrompts}
            isShowingEdit={isShowingEdit}
            setIsShowingEdit={setIsShowingEdit}
          />;
        })}
      </Content>}
      <ButtonGroup>
        <Cancel style={{ minWidth: "127px" }}>
          <OutlineButton forwardedAs="span" onClick={() => onComplete()}>
            {t('cancel', { ns: 'Actions', defaultValue: 'Cancel' })}
          </OutlineButton>
        </Cancel>
        <Button disabled={!selectedPrompt?.id} style={{ minWidth: "127px" }} onClick={() => onComplete(selectedPrompt)}>
          {t('add', { ns: 'Actions', defaultValue: 'Add' })}
        </Button>
      </ButtonGroup>
    </Container>
  );
};

export default Templates;

interface PromptItemProps {
  prompt: Prompt,
  checked: boolean,
  onCheckedChange: (checked: boolean) => void,
  onPreview: () => void,
  isEditable: boolean,
  isShowingEdit?: boolean,
  setIsShowingEdit: Dispatch<SetStateAction<boolean>>,
  refreshMyPrompts: () => void,
  handleDelete: (prompt: { id: number }) => void,
}

function PromptItem(props: PromptItemProps) {
  const { prompt, onCheckedChange, onPreview, isShowingEdit, isEditable, setIsShowingEdit, refreshMyPrompts, handleDelete, checked } = props;
  const { t } = useTranslation();
  const inputRef = React.useRef<HTMLInputElement>(null);

  const clickInput = () => {
    inputRef.current?.click();
  }

  const handlePreviewClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onPreview();
  }

  return <ItemContainer key={`${prompt.id}`} $isChecked={checked}>
    <Input ref={inputRef} type='checkbox' onChange={({ currentTarget: { checked } }) => onCheckedChange(checked)} checked={checked} />
    <Item onClick={clickInput}>
      <PromptTitle>{prompt.label}</PromptTitle>
    </Item>
    <FloatingButtons onClick={(e) => { if (e.target === e.currentTarget) clickInput(); }}>
      <FloatingButton className='preview-button' onClick={handlePreviewClick}>
        <EyeIcon />
      </FloatingButton>
      {isEditable && (
        <ContextMenu $visible={!isShowingEdit} $isChecked={checked} buttons={[
          <SavePromptDialog
            setIsShowingEdit={setIsShowingEdit}
            defaultValue={prompt.prompt || ""}
            editingPrompt={prompt}
            key="edit-button"
            onSuccess={() => refreshMyPrompts()} />,
          <ItemButton key={"delete-button"} onClick={() => handleDelete({ id: prompt.id })}>
            <TrashCanIcon style={{ width: 16 }} color="var(--brand-primary)" />
            <span>{t('meeting-templates.delete-template', 'Delete prompt')}</span>
          </ItemButton>,
        ]} />
      )}
    </FloatingButtons>
  </ItemContainer>;
}

