
import { useState, useEffect, useMemo } from 'react';
import { useParams, useOutletContext, useNavigate } from "react-router-dom";
import { SettingsTabAISummaryEmail } from './SettingsTabAISummaryEmail';
import { SettingsTabIntegrations } from './SettingsTabIntegrations';
import { SettingsTabSubscriptions } from './SettingsTabSubscriptions';
import { SettingsTabLanguage } from './SettingsTabLanguage';
import SettingsManageAdminOrganization from './ManageAdminOrganization';
import { GoogleOAuthProvider } from '@react-oauth/google';
import BuildingIcon from '../Icons/Building';
import WorldIcon from '../Icons/World';
import useUserData from '../hooks/useUserData';
import { useTranslation } from 'react-i18next';
import { I18N_SUPPORT } from '../../config/config';

import styled from 'styled-components';
import { OutletContext } from '../../types/LoaderData';
import { Button } from '../../theme';
import { EmailIcon, StarIcon, PlugIcon } from '../Icons';

const Container = styled.main`
  width: 90%;
  max-width: 980px;
  margin: auto;
`;

const Flex = styled.div`
  display: flex;
  gap: 20px;
`;

const H1 = styled.h1`
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 25px;
`;

const Tabs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 350px;
`;

const Tab = styled(Button)`
  justify-content: flex-start;
  gap: 1rem;
  text-align: left;
`;

const Section = styled.div`
  padding: 20px;
  border: var(--standardBorder);
  border-radius: var(--standardRadius);
  background: var(--backgroundSecondary);
  width: 100%;
`;

const loadTabsWithTranslation = (t) => [
  {
    icon: <EmailIcon size={16} style={{ width: '16px', height: '16px' }} />,
    label: t('settings.ai-summary.tab-label', 'AI summary email'),
    component: SettingsTabAISummaryEmail,
    path: 'ai-summary-email'
  },
  {
    icon: <StarIcon size={16} />,
    label: t('settings.subscription.tab-label', 'Subscriptions'),
    component: SettingsTabSubscriptions,
    path: 'subscriptions',
    isVisible: ({ features }) => Boolean(features?.manage_subscription),
  },
  {
    icon: <PlugIcon size={16} />,
    label: t('settings.integrations.tab-label', 'Integrations'),
    component: SettingsTabIntegrations,
    path: 'integrations',
    isVisible: ({ features }) => Boolean(features?.integrations),
  },
  {
    icon: <BuildingIcon size={16} style={{ width: '16px' }} />,
    label: t('settings.organization.tab-label', 'Manage Organization'),
    component: SettingsManageAdminOrganization,
    path: 'organization',
    isVisible: ({ administering_organization }) => administering_organization != null,
  },
  {
    icon: <WorldIcon size={15} style={{ marginTop: 1 }} />,
    label: t('settings.language.tab-label', 'Language'),
    component: SettingsTabLanguage,
    path: 'language',
    isVisible: () => I18N_SUPPORT,
  }
];

interface Props {
  isManageSlackMode: boolean,
  isManageHubspotMode: boolean,
  isManageSalesforceMode: boolean,
  isManageZoom: boolean,
}

export default function Settings({ isManageSlackMode, isManageHubspotMode, isManageSalesforceMode, isManageZoom }: Props) {
  const [userData] = useUserData();

  const { t, i18n } = useTranslation();
  const settingsTabs = loadTabsWithTranslation(t);

  const navigate = useNavigate();

  const searchParams = useParams();
  const { tab } = searchParams;

  const visibleTabs = useMemo(() => {
    return settingsTabs.filter(({ isVisible }) => {
      if (isVisible == null) return true;

      return isVisible({ administering_organization: userData.administering_organization, features: userData.features });
    });
  }, [userData.administering_organization, i18n.language]);

  const [currentTab, setCurrentTab] = useState(isManageSlackMode || isManageHubspotMode || isManageSalesforceMode || isManageZoom ? visibleTabs[2] : tab ? visibleTabs.find(x => x.path === tab) || visibleTabs[0] : visibleTabs[0]);

  const { brieflyIsInstalled, brieflyUserData, setShowSlackTip, setShowZoomOnboardingPopup } = useOutletContext<OutletContext>();

  useEffect(() => {
    const newTabConfig = visibleTabs.find(x => x.path === tab);

    if (newTabConfig && newTabConfig !== currentTab) {
      setCurrentTab(newTabConfig);
    }
  }, [tab, visibleTabs]);

  useEffect(() => {
    const targetTabPath = localStorage.getItem('targetTab');
    if (targetTabPath) {
      const targetTab = visibleTabs.find(tab => tab.path === targetTabPath);
      if (targetTab) {
        setCurrentTab(targetTab);
      }
      localStorage.removeItem('targetTab'); // Clear the target tab from localStorage
    }
  }, [visibleTabs]);

  const TabComponent = currentTab.component;

  return (
    <Container>
      <H1>{t('settings.title', 'Settings')}</H1>
      <Flex>
        <Tabs>
          {visibleTabs.map(tab => {

            const isSelected = tab.label === currentTab.label;

            return <Tab transparent={!isSelected} full key={tab.path} onClick={() => {
              setCurrentTab(tab);
              navigate(`/settings/${tab.path}`);
            }}>
              {tab.icon}
              {tab.label}
            </Tab>;
          })}
        </Tabs>
        <Section className={"settings-tab-content " + currentTab.path}>
          {currentTab.path === settingsTabs[0].path ? <GoogleOAuthProvider clientId="576404817524-ss93f3dua36hvaum9oi4vqrmu8anc6o3.apps.googleusercontent.com">
            <TabComponent setShowZoomOnboardingPopup={setShowZoomOnboardingPopup} brieflyIsInstalled={brieflyIsInstalled} brieflyUserData={brieflyUserData} />
          </GoogleOAuthProvider> : <TabComponent setShowZoomOnboardingPopup={setShowZoomOnboardingPopup} brieflyIsInstalled={brieflyIsInstalled} brieflyUserData={brieflyUserData} isManageHubspotMode={isManageHubspotMode} isManageSlackMode={isManageSlackMode} isManageSalesforceMode={isManageSalesforceMode} isManageZoom={isManageZoom} setShowSlackTip={setShowSlackTip} />
          }
        </Section>
      </Flex>
    </Container>
  );
}
