import React from "react";
import { SvgSize } from './types';


const TrashCan = (props: SvgSize) => <svg width={props?.size ?? '12'} height={props?.size ?? '12'} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
  <path
    d="M1.5 10.875C1.5 11.5078 1.99219 12 2.625 12H9.375C9.98438 12 10.5 11.5078 10.5 10.875V3H1.5V10.875ZM7.875 4.875C7.875 4.6875 8.03906 4.5 8.25 4.5C8.4375 4.5 8.625 4.6875 8.625 4.875V10.125C8.625 10.3359 8.4375 10.5 8.25 10.5C8.03906 10.5 7.875 10.3359 7.875 10.125V4.875ZM5.625 4.875C5.625 4.6875 5.78906 4.5 6 4.5C6.1875 4.5 6.375 4.6875 6.375 4.875V10.125C6.375 10.3359 6.1875 10.5 6 10.5C5.78906 10.5 5.625 10.3359 5.625 10.125V4.875ZM3.375 4.875C3.375 4.6875 3.53906 4.5 3.75 4.5C3.9375 4.5 4.125 4.6875 4.125 4.875V10.125C4.125 10.3359 3.9375 10.5 3.75 10.5C3.53906 10.5 3.375 10.3359 3.375 10.125V4.875ZM10.875 0.75H8.0625L7.82813 0.328125C7.73438 0.140625 7.52344 0 7.33594 0H4.64063C4.45313 0 4.21875 0.140625 4.14844 0.328125L3.9375 0.75H1.125C0.914062 0.75 0.75 0.9375 0.75 1.125V1.875C0.75 2.08594 0.914062 2.25 1.125 2.25H10.875C11.0625 2.25 11.25 2.08594 11.25 1.875V1.125C11.25 0.9375 11.0625 0.75 10.875 0.75Z"
    fill="currentColor" />
</svg>;

export default TrashCan;