import styled, { css } from 'styled-components';
import { Button } from '../../../theme';
import { MessageType } from './types';
import Dialog from '../../Reusable/Dialog';


export const Container = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const SparkleContainer = styled.span`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  left: -40px;
  top: 10px;
  background: linear-gradient(var(--focusGradientPrimary));
  color: white;
  border-radius: 50%;
`;

export const ChatControl = styled.div`
  display: flex;
  background-color: rgba(255, 255, 255, 0.85);
  padding: 15px 20px;
  gap: 20px;
  box-sizing: border-box;
  border-top: var(--standardBorder);
  border-radius: var(--standardRadius);
  margin-top: auto;
`;

export const ChatLogContainer = styled.div`
  border: var(--standardBorder);
  border-radius: var(--standardRadius);
  background-color: var(--gray300);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 300px);
`;

export const ChatScroll = styled.div`
  overflow-y: scroll;
  flex-basis: 100%;
  scrollbar-color: var(--textSecondary) transparent;
`;

export const ChatLog = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding-bottom: 30px;
  max-width: 50vw;
  min-width: 700px;
  margin: 0 auto;
`;

export const PromptFormLoaderOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PromptFormLabel = styled.label`
  display: block;
  font-size: 14px;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`;

export const PromptFormError = styled.p`
  color: var(--error);
  font-weight: bold;
  font-size: 14px;
  margin: 1rem;
  text-align: center;
`;

export const PromptFormInput = styled.input`
  display: block;
  border-radius: var(--standardRadius);
  margin: 0;
  height: 2.5rem;
  width: 100%;
  border: none;
  font-size: 16px;
  padding: 10px;
  font-family: inherit;
  background-color: var(--backgroundSecondary);
  box-sizing: border-box;
  border: var(--standardBorder);
  transition: border-color 0.1s;

  &:focus {
    outline: none;
    border-color: var(--brand-secondary);
  }
`;

export const PromptFormTextarea = styled.textarea`
  display: block;
  border-radius: var(--standardRadius);
  margin: 0;
  margin-bottom: 1rem;
  height: 8rem;
  width: 100%;
  border: none;
  resize: none;
  font-size: 16px;
  padding: 10px;
  font-family: inherit;
  background-color: var(--backgroundSecondary);
  box-sizing: border-box;
  border: var(--standardBorder);
  transition: border-color 0.1s;

  &:focus {
    outline: none;
    border-color: var(--brand-secondary);
  }
`;

export const PromptFormButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  gap: 10px;
`;

export const ContextMenuContainer = styled.div`
  visibility: hidden;
  position: relative;
  top: -0.5rem;

  &:focus-within {
    visibility: visible;
  }
`;

export const ContextMenuButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: var(--brand-primary);
  border-radius: 99rem;
  padding-top: 0.2rem;
  margin: 0 0.5rem;
  aspect-ratio: 1;

  ${ContextMenuContainer}:focus-within &, &:hover {
    background-color: rgb(from var(--brand-primary) r g b / 0.1);
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const ContextMenu = styled.ul`
  padding: 0;
  width: fit-content;
  min-width: 10rem;
  list-style: none;
  position: absolute;
  top: 0.5rem;
  left: 1.2rem;
  display: flex;
  flex-direction: column;
  gap: 4px;
  background: white;
  border: var(--standardBorder);
  border-radius: var(--standardRadius);
  visibility: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 700;

  ${ContextMenuContainer}:focus-within & {
    visibility: visible;
  }
`;

export const ContextMenuItem = styled.li`
  padding: 0;
  width: 100%;

  &:hover {
    background: var(--primary100);
  }

  &:first-child {
    border-top-left-radius: var(--standardRadius);
    border-top-right-radius: var(--standardRadius);
  }

  &:last-child {
    border-bottom-left-radius: var(--standardRadius);
    border-bottom-right-radius: var(--standardRadius);
  }
`;

export const ItemButton = styled.button`
  background: none;
  border: none;
  margin: 0;
  padding: 0.5rem 0.8rem;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const Bubble = styled.li<{ $messageType?: MessageType; }>`
  position: relative;
  padding: 16px;
  border-radius: var(--standardRadius);
  margin-top: 20px;
  display: flex;

  p {
    margin: 0;
  }

  &.user {
    align-self: flex-end;
    background-color: white;
    margin-left: 2rem;
  }


  &.assistant {
    align-self: flex-start;
    background: var(--secondary100);
    margin-right: 2rem;

    ${({ $messageType }) => $messageType === 'fileChangeNote' && (css`
      font-size: 12px;
      color: var(--gray500);
      background: none;
    `)}

    & + .assistant {
      margin-top: 0.5rem;
    }
  }

  &.streaming-message {
    flex-direction: column;
    padding-right: 2rem;
  }

  a em {
    font-size: 10px;
    vertical-align: text-top;
    text-decoration: underline;
    color: var(--primary600);
  }

  &:hover ${ContextMenuContainer}, &:focus-within ${ContextMenuContainer} {
    visibility: visible;
  }
`;

const TextAreaCss = (css`
  box-sizing: border-box;
  border: none;
  flex-grow: 1;
  width: 100%;
  font-family: inherit;
  flex-grow: 1;
  resize: none;
  overflow: hidden;
  font-size: 16px;
  &:focus {
    outline: none;
  }

  grid-area: 1 / 1 / 2 / 2;
`);

export const ChatInput = styled.textarea`
  ${TextAreaCss}
`;

export const ChatInputWrapper = styled.div`
  display: grid;
  width: 100%;
  max-height: 12rem;
  overflow-y: auto;
  padding: 0.2rem 0.5rem;
  margin-right: 0.2rem;

  &::after {
    content: attr(data-textarea-content) " ";
    white-space: pre-wrap;
    visibility: hidden;
  }

  & ${ChatInput} {
    resize: none;
    overflow: hidden;
  }

  &::after, & ${ChatInput} {
    ${TextAreaCss}
  }
`;

export const Form = styled.form`
  display: flex;
  align-items: center;
  border: var(--standardBorder);
  border-radius: var(--standardRadius);
  width: 100%;
  padding: 0 4px;
  &:focus-within {
    outline: 2px solid var(--brand-primary);
  }
`;

export const SubmitButton = styled(Button)`
  width: 100px;
`;

export const EmptyState = styled.span`
  align-self: center;
  color: var(--gray500);
  margin: 50px 0;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h1`
  margin: 0;
  font-size: 24px;
`;

export const TitleHelper = styled.span<{ $primary?: boolean; }>`
  font-size: 20px;
  font-weight: bold;

  ${({ $primary }) => $primary && css`
    color: var(--primary600);
  `}
`;


export const SubTitle = styled.h2`
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: var(--gray500);
`;

export const TemplateDialog = styled(Dialog)`
  // show & hide entire portal when secondary modal is open
  &:has(div[data-visible=false]) {
    visibility: hidden;
  }
  &:has(div[data-visible=true]) {
    visibility: visible;
  }
`;
