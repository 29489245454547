import { TaskProps } from "./types";
import formEntriesToBody from "../../../utils/formEntriesToBody";
import { Button, FlexColumn10, FlexEnd10 } from "../../../theme";
import ReviewFieldMappings from "./ReviewFieldMapping";
import { useTranslation } from "react-i18next";
import { ThreeDots } from "react-loader-spinner";

export default function ConfirmChatter(props: TaskProps): JSX.Element {
  const { updateWorkflowRun, isLoading } = props;
  const { t } = useTranslation()

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const data = formEntriesToBody(new FormData(form));

    updateWorkflowRun({ chatter: data });
  }

  return (
    <FlexColumn10>
      <ReviewFieldMappings {...props} />
      <FlexEnd10 as="form" onSubmit={handleSubmit}>
        <input type="hidden" name="state" value="approved" />
        <Button disabled={isLoading}>
          {isLoading ? (
            <ThreeDots height={18} width={22} color="white" />
          ) : (
            t("workflow_run.chatter.submit", "Post in Chatter")
          )}
        </Button>
      </FlexEnd10>
    </FlexColumn10>
  )
}


