import styled from "styled-components";

import { FlexColumn10, FlexStart10 } from "../../../theme";

export const CenteredPanel = styled(FlexColumn10)`
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
`;
export const SubTitle = styled.span``;

export const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderRow = styled(FlexStart10)`
  align-items: center;
`;

export const ObjectName = styled.span`
  margin-left: auto;
`;

