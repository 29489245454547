import { ComponentProps, useContext } from "react"
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import { ChatThreadContext } from "../../../ChatThreadProvider";

type AnchorProps = ComponentProps<'a'>;

export const TransformAnchorForChat = (props: AnchorProps) => {
  const { href: propHref, children } = props;
  const href = propHref as string;
  const { currentTranscripts } = useContext(ChatThreadContext);
  const transcriptId = Number(href.split('bkend-').pop());

  const { i18n } = useTranslation();
  
  const transcriptInfo = currentTranscripts.find((t) => t.id === transcriptId);
  const tooltipId: string = transcriptInfo ? `transcript-${transcriptInfo.id}` : href;

  const tooltipLabel = transcriptInfo ? `${transcriptInfo.meeting_name} - ${new Date(transcriptInfo.meeting_start_date_time * 1000).toLocaleDateString(i18n.language, {dateStyle: 'medium'})}` : href;

  return (
    <>
      <Tooltip id={tooltipId} place="top">{tooltipLabel}</Tooltip>
      <Link data-tooltip-id={tooltipId} to={{pathname: href}} state={{from: location.pathname}}>{children}</Link>
    </>
  );
}


