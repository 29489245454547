import { TaskProps } from "./types";

import { useTranslation } from "react-i18next";

import { CenteredPanel, SubTitle } from "./style";
import SearchObject from "./SearchObject";

export default function FindSalesforceObjectSelect(props: TaskProps) {
  const { t } = useTranslation();
  const { workflowRun: { objectType } } = props;

  return <CenteredPanel>
    <SubTitle>
      {t('workflow_run.find-salesforce-object.no-object.title', `Briefly couldn’t detect a ${objectType} for this call`, { objectType })}
    </SubTitle>
    <SearchObject {...props} />
  </CenteredPanel>
}


