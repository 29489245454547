import styled, { css } from "styled-components";
import { Button } from "../../../theme";

export const LoadingAnimation = styled.div`
  margin: auto;
  width: 30px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 85vh;
`;

export const MeetingList = styled.div`
  padding: 0 2px;
  display: flex;
  flex-direction: column;
  min-height: 400px;
  max-height: calc(85vh - 120px);
  overflow-y: auto;
  gap: 10px;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const DateText = styled.span`
  font-weight: bold;
  font-size: 14px;
  color: var(--gray800);
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const GroupRow = styled(Row)`
  padding: 4px 0;
  position: sticky;
  top: 0px;
  background-color: var(--white);
`;

export const Centered = styled.div`
  margin: auto;
`;

export const SearchRow = styled(Row)`
  justify-content: flex-end;
  gap: 11px;
  font-size: 12px;

  & > *:first-child {
    max-width: 300px;
    margin-right: auto;
    flex-grow: 1;
  }

  .search__control {
    border-radius: 15px;
    min-height: 30px;
  }

  .search__control > svg {
    color: var(--gray500);
    margin-left: 5px;
  }

  .search__indicators {
    display: none;
  }

  .rs__control {
    min-width: 125px;
    min-height: 30px;
  }

  .rs__control > svg {
    color: var(--gray500);
    margin-left: 5px;
  }

  .rs__clear-indicator {
    padding: 2px;

    svg {
      height: 12px !important;
      width: 12px !important;
    }
  }

  .rs__dropdown-indicator {
    padding: 2px;
  }
`;

export const Column = styled.div<{ $center?: boolean; }>`
  display: flex;
  flex-direction: column;
  ${({ $center }) => $center && css`align-items: center;`}

  &.attendees {
    gap: 3px;
  }
`;

export const FloatingTriggerButton = styled(Button)`
  position: absolute;
  right: 3rem;
  top: 1rem;
  z-index: 99;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 12px;
  border-radius: 99rem;
  font-size: 0.9rem;
  border: none;
  vertical-align: middle;
  font-weight: 200;
  gap: 0;
  transition: gap 0.5s;

  & b {
    font-weight: 800;
  }

  &:hover {
    aspect-ratio: unset;
    opacity: 1;
    gap: 5px;
  }

  & .show-on-hover {
    max-width: 0;
    overflow: hidden;
    transition: max-width 0.5s;
  }

  &:hover .show-on-hover {
    max-width: 10rem;
  }
`;

export const RowContainer = styled(Row) <{ $active: boolean; }>`
  border: var(--standardBorder);
  padding: 8px;
  border-radius: 6px;
  font-size: 11px;
  color: var(--gray500);
  cursor: pointer;

  ${({ $active }) => $active && css`
    outline: 2px solid var(--primary600);
  `}
  ${[2, 9, 44, 25, 20].map((percent, i) => css`
    & > *:nth-child(${i + 1}) {
      width: ${percent}%;
    }
  `)}

  & > *:nth-child(2) {
    border-right: var(--standardBorder);
    margin-right: 8px;
  }
`;

export const MeetingTitle = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: bold;
  color: var(--gray800);
`;

export const LinkButton = styled(Button)`
  background: none;
  border: none;
  color: var(--gray600);
  font-weight: normal;
  margin-left: auto;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  color: var(--gray600);
  font-weight: normal;
  margin-left: auto;
  border-radius: 99rem;
  padding: 0.5rem;
  height: 2rem;
  width: 2rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;

  &:hover {
    background-color: var(--gray300);
  }
`;