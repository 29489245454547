import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Markdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import remarkGfm from "remark-gfm";
import { ChatThreadContext } from "../../../ChatThreadProvider";
import { FlexColumn10, Button } from "../../../theme";
import { ContextMenuWithActions } from "./ChatContextMenu";
import { Bubble } from "./styles";
import { ChatBubbleProps } from "./types";
import { TransformAnchorForChat } from "./MarkdownTransformers";

export function BubbleMarkdown({ text }: { text: string }) {
  return <div><Markdown components={{ a: TransformAnchorForChat }} remarkPlugins={[remarkGfm, remarkBreaks]}>
    {text}
  </Markdown></div>
}

export function ChatBubble({ message, icon }: ChatBubbleProps): JSX.Element {
  const { t } = useTranslation()
  const { setIsEditingMeetings } = useContext(ChatThreadContext);
  const text = message.content.map((c) => c.text.value).join();

  switch (message.metadata.messageType) {
    case "fileChangeNote":
      return <Bubble className={message.role} $messageType={message.metadata.messageType}>
        {icon}
        <BubbleMarkdown text={text} />
      </Bubble>;
    case "getStartedNote":
      return <Bubble className={message.role} $messageType={message.metadata.messageType}>
        <FlexColumn10>
          <>
            <span>
              {t("chat-log.greeting.add-meetings-copy", "Hi there! I’m your AI assistant. I summarize meetings, create deliverables, and answer questions. Start by uploading a few transcripts you want me to reference.")}
            </span>
            <Button
              small
              onClick={() => setIsEditingMeetings(true)}
            >
              {t("chat-log.greeting.add-meetings-button", "Add transcripts")}
            </Button>
          </>
        </FlexColumn10>
      </Bubble >;
    default:
      return <Bubble className={message.role} $messageType={message.metadata.messageType}>
        {icon}
        <BubbleMarkdown text={text} />
        <ContextMenuWithActions text={text} />
      </Bubble>;

  }
}

