import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

interface Props extends React.HTMLProps<HTMLDivElement> {}

const Pre = styled.div`
  margin: 0;
  white-space: pre;
  text-wrap: pretty;
  outline: none;
`;

export default function EditableContentTextarea({ name, ...props }: Props): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState(props.defaultValue || '');
  const handleInput: React.FormEventHandler<HTMLDivElement> = (e) => {
    setValue(e.currentTarget.textContent || '');
  };

  useEffect(() => {
    if (props.autoFocus && ref.current) {
      ref.current.focus();
    }
  }, [props.autoFocus]);

  return <>
    <Pre contentEditable={!props.readOnly} onBlur={handleInput} {...props} ref={ref} suppressContentEditableWarning>{value}</Pre>
    <input type="hidden" value={value} name={name} />
  </>
}
