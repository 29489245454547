import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { trackEvent } from '../../utils/genericUtils';
import styled, { css } from 'styled-components';
import { PlusIcon, SparklesIcon, TrashCanIcon } from '../Icons';
import { ReactComponent as EditSvg } from '../Icons/edit.svg';
import Accordion from '../Reusable/Accordion';
import { Button } from '../../theme';
import { ChatThreadContext } from '../../ChatThreadProvider';
import Alert from '../Reusable/Alert';
import { apiDelete, apiPatch } from '../../utils/api';
import { ChatThread, UpdateFields } from '../Chat/ShowChat/types';
import { ThreeDots } from 'react-loader-spinner';

interface Props {
  isActive: boolean;
}

const RenameAlertBody = styled.div`
  display: flex;
  align-items: center;
  margin: 2rem 0;
  gap: 1rem;

  span {
    display: block;
    flex-shrink: 0;
  }
`;

const RenameInput = styled.input`
  box-sizing: border-box;
  border: 2px solid var(--brand-primary);
  border-radius: 8px;
  padding: 0.5em 1em;
  font-weight: lighter;
  color: var(--gray600);
  display: block;
  flex-basis: 100%;
  background: var(--gray300);
  font-size: 1rem;
  margin-right: 0;
  margin-left: auto;

  &:hover, &:focus {
    outline: none;
    border-color: var(--brand-secondary);
  }
`;

const HoveringContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  gap: 0.2rem;
  top: 0;
  bottom: 0;
  padding: 0.4rem;
  right: 0;
  opacity: 0;
  background: transparent;
  border-radius: 0 8px 8px 0;
`;

const HoveringButton = styled.button`
  background: var(--gray300);
  border: none;
  border-radius: 1rem;
  aspect-ratio: 1;
  width: 2rem;
  cursor: pointer;
  color: var(--brand-secondary);

  &:hover {
    background: var(--secondary600);
    color: var(--gray300);
  }

  svg {
    width: 0.9rem;
    height: 0.9rem;
  }

  svg[aria-label="edit"] {
      position: relative;
      left: 1%;
      bottom: -3%;
  }

  svg[aria-label="delete"] {
      position: relative;
      left: 1%;
      bottom: -2%;
  }
`;

const Row = styled.div<{ $isActive?: boolean; }>`
  display: flex;
  align-items: center;
  position: relative;
  font-size: 0.9rem;
  padding: 8px 0;
  min-height: 2.5rem;

  ${({ $isActive }) => $isActive && css`
  background: linear-gradient(var(--focusGradientSecondary));
  border: var(--standardBorder);
    a {
      font-weight: bold;
      color: var(--primary600);
    }
  `}

  a {
    display: flex;
    gap: 8px;
    width: 100%;
    border-radius: var(--standardRadius);
    cursor: pointer;
  }

  &:not(:first-child) {
    border: ${({ $isActive }) => $isActive ? (css`
      var(--standardBorder)`) : `1px solid transparent`
  };
    border-radius: var(--standardRadius);
    padding: 0px 8px;

    a {
      width: calc( 100% - 80px);
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &:hover:not(:first-child) {
    border: var(--standardBorder);
  }

  &:hover ${HoveringContainer} {
    opacity: 1;
  }

  &:focus-within ${HoveringContainer} {
  opacity: 1;
  }
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(from var(--background) r g b / 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
`;

const ChatAccordion = styled(Accordion)`
  
`;
interface RenameChatDialogProps {
  chatId: string | null;
  oldName: string | null;
  onClose: () => void;
  onSuccess: (newName: string) => void;
}


const RenameChatDialog = (props: RenameChatDialogProps) => {
  const { chatId, onSuccess, onClose, oldName } = props;
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>(oldName ?? '');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    setInputValue(oldName ?? '');
  }, [oldName]);

  const handleSubmit = async () => {
    const cleansedInput = inputValue.trim();
    if (cleansedInput.length > 0) {
      setIsSubmitting(true);
      try {
        await apiPatch<UpdateFields, ChatThread, 'chat_thread'>({
          path: `/chat-threads/${chatId}`,
          body: { 'title': cleansedInput }
        });
        onSuccess(cleansedInput);
        onClose();
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <>
      {isSubmitting &&
        <LoadingOverlay>
          <ThreeDots color='var(--brand-primary)' />
        </LoadingOverlay>
      }
      <Alert
        open={chatId !== null}
        onOpenChange={(effect) => { if (effect === false) onClose(); }}
        title={t('chat.rename.title', 'Rename Chat')}
        cancelContent={t('cancel', { ns: 'Actions', defaultValue: 'Cancel' })}
        confirmContent={t('rename', { ns: 'Actions', defaultValue: "Rename" })}
        onConfirm={() => handleSubmit()}>
        <RenameAlertBody>
          <span>{t('chat.rename.new-name-label', 'New Name:')}</span>
          <RenameInput placeholder={oldName ?? undefined} value={inputValue} onChange={(e) => setInputValue(e.target.value)} type="text" />
        </RenameAlertBody>
      </Alert>
    </>
  );
};

interface DeleteChatDialogProps {
  chatId: string | null;
  chatName: string | null;
  onClose: () => void;
  onSuccess: (deletedId: string) => void;
}

const DeleteChatDialog = (props: DeleteChatDialogProps) => {
  const { chatId, chatName, onClose, onSuccess } = props;
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const { t } = useTranslation();

  const handleDeleteChat = async () => {
    setIsDeleting(true);
    try {
      await apiDelete(`/chat-threads/${chatId}`);
      onSuccess(chatId as string);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <>
      {isDeleting &&
        <LoadingOverlay>
          <ThreeDots color='var(--brand-primary)' />
        </LoadingOverlay>
      }
      <Alert
        open={chatId !== null}
        onOpenChange={(effect) => { if (effect === false) onClose(); }}
        title={t('chat.delete.title', "Delete Chat")}
        cancelContent={t('cancel', { ns: 'Actions', defaultValue: 'Cancel' })}
        confirmContent={t('delete', { ns: 'Actions', defaultValue: 'Delete' })}
        onConfirm={() => handleDeleteChat()}
        description={
          <Trans
            i18nKey="chat.delete.content"
            values={{ chatName }}
          >
            Are you sure you want to delete <strong>{chatName ?? ''}</strong>?
            All the messages in this chat will be lost.
          </Trans>
        }
      />
    </>
  );
};

const Chat = ({ isActive }: Props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { chatThreads: chats, loadChatThreads, autoRenamingIds, setCurrentChatThreadUUID } = useContext(ChatThreadContext);
  const navigate = useNavigate();

  const [renameChatId, setRenameChatId] = useState<string | null>(null);
  const chatToRename = renameChatId !== null ? chats.find((chat) => chat.uuid === renameChatId) : undefined;

  const [chatToDeleteId, setChatToDeleteId] = useState<string | null>(null);
  const chatToDelete = chatToDeleteId !== null ? chats.find((chat) => chat.uuid === chatToDeleteId) : undefined;

  const handleChatNavClick = () => {
    trackEvent("briefly-chat-navigate", {});
  };

  const handleRenameSuccess = () => {
    loadChatThreads(new AbortController().signal);
    trackEvent("briefly-chat-rename", {});
  };

  const handleDeleteSuccess = (deletedId: string) => {
    loadChatThreads(new AbortController().signal, (newThreads) => {
      if (!pathname.includes(deletedId)) {
        return;
      }

      if (newThreads.length > 0) {
        navigate(`/chat/${newThreads[0].uuid}`, { replace: true });
      } else {
        navigate('/meetings', { replace: true });
      }
    });
    trackEvent("briefly-chat-delete", {});
  };

  return (
    <ChatAccordion
      title={t('root-sidebar.chat.dropdown', 'Chat')}
      icon={<span style={{ color: 'var(--primary600)' }}><SparklesIcon size={16} /></span>}
      defaultOpen
      isActive={isActive}
    >
      <RenameChatDialog
        chatId={renameChatId}
        oldName={chatToRename?.title ?? null}
        onSuccess={() => handleRenameSuccess()}
        onClose={() => setRenameChatId(null)}
      />
      <DeleteChatDialog
        chatId={chatToDeleteId}
        chatName={chatToDelete?.title ?? null}
        onSuccess={(deletedId) => handleDeleteSuccess(deletedId)}
        onClose={() => setChatToDeleteId(null)} />
      <Row>
        <Link to="/chat" style={{ display: 'flex', alignItems: 'center' }}>
          <Button full>
            <PlusIcon />
            <span>
              {t('root-sidebar.chat.new-chat', 'New Chat')}
            </span>
          </Button>
        </Link>
      </Row>
      {chats.sort((a, b) => b.created_on - a.created_on).map((chat) => {
        const isBeingAutoRenamed = autoRenamingIds.includes(chat.uuid);
        return (
          <Row key={chat.uuid} $isActive={pathname.includes(chat.uuid)}>
            <Link to={`/chat/${chat.uuid}`} onClick={handleChatNavClick}>
              {isBeingAutoRenamed &&
                <ThreeDots
                  wrapperStyle={{ position: 'absolute', right: '1rem', top: '0', bottom: '0' } as Record<keyof React.CSSProperties, string>}
                  width={'2rem'}
                  height={'100%'}
                  color='var(--brand-primary)' />
              }
              {chat.title}
            </Link>
            <HoveringContainer>
              <HoveringButton onClick={() => setRenameChatId(chat.uuid)}>
                <EditSvg aria-label="edit" />
              </HoveringButton>
              <HoveringButton onClick={() => setChatToDeleteId(chat.uuid)}>
                <TrashCanIcon aria-label="delete" />
              </HoveringButton>
            </HoveringContainer>
          </Row>
        )
      })}
    </ChatAccordion>
  );
};

export default Chat;
