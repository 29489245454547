
/* eslint-disable react/prop-types */
import { useUserData } from '../hooks/useUserData';
import { setHubspotIntegrationIsActive, setHubspotGrowthOpportunitiesActive, setSalesforceIntegrationIsActive, setSalesforceGrowthOpportunitiesActive, trackEvent, disconnectHubspot, disconnectSalesforce } from '../../utils/genericUtils';
import { HUBSPOT_CONNECT_LINK, SALESFORCE_CONNECT_LINK } from '../../config/config';
import { Tooltip } from 'react-tooltip';
import LinkWithStaticInfoPopup from '../controls/LinkWithStaticInfoPopup';
import SalesforceSetupInfo1 from '../../images/salesforce-setup.png';
import SalesforceSetupInfo2 from '../../images/salesforce-setup-2.png';
import SalesforceSetupInfo3 from '../../images/salesforce-setup-3.png';
import { Trans, useTranslation } from 'react-i18next';
import { CaretIcon, QuestionMarkIcon, } from '../Icons';
import { Button, ButtonLink, HR, FlexCenter10, H2, BodyLight, FlexColumn10 } from '../../theme';
import styled from 'styled-components';


const BackButton = styled(ButtonLink)`
  margin-bottom: 16px;
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 25px;
`;
const Integration = styled.span`
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
`;
const Switch = styled.label`
  align-self: center;
`;

import HubspotSalesForceNoCalendarConnectedPopup from './HubspotSalesForceNoCalendarConnectedPopup';
import IssueDetected from '../Reusable/IssueDetected';
import ProBadge from '../Reusable/ProBadge';


function AddToIntegrationTooltip({ integrationName }) {
  const { t } = useTranslation();

  return <div style={{
    maxWidth: "350px", textAlign: "left", lineHeight: "18px", letterSpacing: "0.3px"
  }}>
    <b>{t('settings.hubspot-salesforce.connected.features.ai-summary.tooltip', 'How does this work ?')}</b><br />
    <ul className='how-it-works-steps'>
      <li>
        <Trans i18nKey='settings.hubspot-salesforce.connected.features.ai-summary.tooltip-steps.1' values={{ integrationName }}>
          First Google Calendar event attendee of Google Meet meeting, whose email exists as {integrationName} contact, is associated with created {integrationName} meeting.
        </Trans>
      </li>
      <li>
        <Trans i18nKey='settings.hubspot-salesforce.connected.features.ai-summary.tooltip-steps.2'>
          If contact could not be detected, Briefly will send message in Slack asking which contact to associate meeting with (if Slack is connected to Briefly account)
        </Trans>
      </li>
      <li>
        <Trans i18nKey='settings.hubspot-salesforce.connected.features.ai-summary.tooltip-steps.3' values={{ integrationName }}>
          If transcript is too long for {integrationName} meeting notes, only summary is added.
        </Trans>
      </li>
    </ul>
  </div>;
}

const ManageHubspotSalesforceMode = ({
  isSalesforce,
  setShowHubspotCalendarRequiredPopup,
  showHubspotCalendarRequiredPopup,
  userIsPaid,
}) => {
  const { t } = useTranslation();

  const [{ email_google_calendar, is_integration_active_google_calendar,
    is_integration_active_hubspot, is_growth_opportunities_active_hubspot,
    salesforce_integration_is_visible, is_integration_active_salesforce,
    is_growth_opportunities_active_salesforce,
    is_hubspot_connected, is_salesforce_connected, hubspot_integration_is_visible,
    salesforce_api_disabled_error,
  }, forceRenewUserData] = useUserData();

  const calendarIsIntegrated = is_integration_active_google_calendar;


  const isIntegrationConnected = isSalesforce ? is_salesforce_connected : is_hubspot_connected;

  const integrationName = isSalesforce ? 'Salesforce' : 'Hubspot';

  const isIntegrationVisible = isSalesforce ? salesforce_integration_is_visible : hubspot_integration_is_visible;

  const isIntegrationActive = isSalesforce ? is_integration_active_salesforce : is_integration_active_hubspot;

  const isGrowthOpportunitiesActive = isSalesforce ? is_growth_opportunities_active_salesforce : is_growth_opportunities_active_hubspot;

  const setIntegrationIsActive = isSalesforce ? setSalesforceIntegrationIsActive : setHubspotIntegrationIsActive;

  const setGrowthOpportunitiesIsActive = isSalesforce ? setSalesforceGrowthOpportunitiesActive : setHubspotGrowthOpportunitiesActive;

  const onConnectHubspotClick = () => {
    trackEvent('hubspot-connect-clicked', {});
    setTimeout(() => {
      window.location = HUBSPOT_CONNECT_LINK;
    }, 500);
  };

  const onConnectSalesforceClick = () => {
    trackEvent('salesforce-connect-clicked', {});
    setTimeout(() => {
      window.location = SALESFORCE_CONNECT_LINK;
    }, 500);
  };

  const onDisconnectHubspotClick = () => {
    disconnectHubspot(() => {
      forceRenewUserData();
    });
  };

  const onDisconnectSalesforceClick = () => {
    disconnectSalesforce(() => {
      forceRenewUserData();
    });
  };

  const onConnectClick = isSalesforce ? onConnectSalesforceClick : onConnectHubspotClick;

  const onDisconnectClick = isSalesforce ? onDisconnectSalesforceClick : onDisconnectHubspotClick;

  return <>
    {showHubspotCalendarRequiredPopup && !is_integration_active_google_calendar && (<HubspotSalesForceNoCalendarConnectedPopup {...{ integrationName, setShowHubspotCalendarRequiredPopup, calendarIsIntegrated, forceRenewUserData, email_google_calendar }} />)
    }
    <BackButton transparent small to='/settings/integrations' >
      <CaretIcon style={{ transform: "rotate(90deg)" }} />
      <span>{t('back', { ns: 'Actions', defaultValue: 'Back' })}</span>
    </BackButton>
    <FlexColumn10 id="settings-integrations" className="hubspot-salesforce">
      <Row>
        <H2>{t('settings.hubspot-salesforce.title', { integrationName, defaultValue: '{{ integrationName }} integration' })}</H2>
        <div>
          {salesforce_api_disabled_error ? <IssueDetected /> : ''}
          {!userIsPaid && (
            <ProBadge />
          )}
          {isIntegrationConnected &&
            <Button medium onClick={onDisconnectClick}>
              {t('disconnect', { ns: 'Actions', defaultValue: 'Disconnect' })}
            </Button>
          }
        </div>
      </Row>
      {salesforce_api_disabled_error && <>
        <h6>
          ⚠️ {t('settings.salesforce-api-disabled.title', 'Briefly cannot connect to your Salesforce account due to API access permissions.')}
        </h6>
        <BodyLight>
          {t('settings.salesforce-api-disabled.steps.title', 'Follow the steps below to enable permissions and try again:')}
          <ul style={{ paddingLeft: 20 }}>
            <li>
              <Trans i18nKey={'settings.salesforce-api-disabled.steps.1.label'}>
                Go to Salesforce <i>Setup</i> from the cog icon on the top right of your account.
              </Trans>{' '}
              <LinkWithStaticInfoPopup linkLabel={t("settings.salesforce-api-disabled.steps.search-popup-label", "Where is this?")} popupHeader={t("settings.salesforce-api-disabled.steps.1.popup-header", "Go to Salesforce Setup")} popupContent={<img src={SalesforceSetupInfo1} />} />
            </li>
            <li>
              <Trans i18nKey={'settings.salesforce-api-disabled.steps.2.label'}>
                In Quick Find type <i>Profiles</i> and click on <i>Profiles</i> in the column below.
              </Trans>{' '}
              <LinkWithStaticInfoPopup linkLabel={t("settings.salesforce-api-disabled.steps.search-popup-label", "Where is this?")} popupHeader={t("settings.salesforce-api-disabled.steps.2.popup-header", "Open Profiles")} popupContent={<img src={SalesforceSetupInfo2} />} />
            </li>
            <li>
              <Trans i18nKey={'settings.salesforce-api-disabled.steps.3.label'}>
                Find and click on the Profile Name that applies to the User in question.
              </Trans>
            </li>
            <li>
              <Trans i18nKey={'settings.salesforce-api-disabled.steps.4.label'}>
                Scroll down to Administrative Permissions and find the option for API Enabled.
              </Trans>
              {' '}
              <LinkWithStaticInfoPopup linkLabel={t("settings.salesforce-api-disabled.steps.search-popup-label", "Where is this?")} popupHeader={t("settings.salesforce-api-disabled.steps.4.popup-header", "Check API Enabled box")} popupContent={<img src={SalesforceSetupInfo3} />} />
            </li>
          </ul>
          <BodyLight>
            {t('settings.salesforce-api-disabled.note-plan', 'Salesforce API access is included only in Professional, Enterprise, Performance, Unlimited, and Developer plan. It can be purchased as add-on for other plans.')}
          </BodyLight>
        </BodyLight>
        <HR />
      </>}
      {!isIntegrationConnected && userIsPaid &&
        <>
          <HR />
          <Row>
            <div>
              <Integration>
                {t('settings.hubspot-salesforce.connect.title', { integrationName, defaultValue: 'Connect {{ integrationName }} to enable the features below' })}
              </Integration>
            </div>
            <Button medium onClick={onConnectClick}>{t('settings.hubspot-salesforce.connect.action', { integrationName, defaultValue: 'Connect {{ integrationName }}' })}</Button>
          </Row></>}

      {isIntegrationVisible ? (
        <FlexColumn10>
          <Integration>
            {t('settings.hubspot-salesforce.connected.features.title', { integrationName, defaultValue: 'Select the {{ integrationName }} features you would like to activate' })}
          </Integration>
          <BodyLight>
            {t('settings.hubspot-salesforce.connected.features.note', { integrationName, defaultValue: 'Note that in order to identify Growth opportunities, Briefly will have to add Transcripts to {{ integrationName }}.' })}
          </BodyLight>
          <FlexCenter10>
            <div className="hubspot-option" style={{
              background: isIntegrationActive ? 'var(--secondary100)' : 'var(--white)',
              borderRadius: 8,
              border: isIntegrationActive ? '1px solid var(--secondary600)' : 'var(--standardBorder)',
              opacity: isIntegrationActive ? '1' : '.6',
              display: 'flex',
              width: '40%',
              padding: 24,
              paddingLeft: 16,
              cursor: 'pointer'
            }} onClick={() => {
              if (!isIntegrationActive && !is_integration_active_google_calendar) {
                setShowHubspotCalendarRequiredPopup(true);
                return;
              }
              setIntegrationIsActive(!isIntegrationActive);
            }}>
              <div style={{ display: 'inline-flex', width: 30 }}>
                {isIntegrationActive ?
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1" y="1" width="28" height="28" rx="4" fill="none" stroke="var(--brand-primary)" strokeWidth="2" />
                    <path d="M20.798 10.236C21.4774 9.55356 22.5816 9.55093 23.2639 10.2305V10.2305C23.9464 10.9102 23.9483 12.0147 23.268 12.6968L14.0032 21.986C13.4437 22.547 12.5362 22.5508 11.9719 21.9946L6.7552 16.8526C6.06386 16.1712 6.06097 15.0567 6.74878 14.3717V14.3717C7.42642 13.6968 8.52087 13.6925 9.20372 14.3621L12.6185 17.7108C12.8252 17.9135 13.1571 17.911 13.3613 17.7059C16.2445 14.8097 18.1057 12.9402 20.798 10.236Z" fill="var(--brand-primary)" />
                  </svg>

                  :
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="29" height="29" rx="4.5" fill="none" stroke="var(--textPrimary)" />
                  </svg>
                }
              </div>
              <div style={{ display: 'inline-block', width: 'calc(100% - 40px)', marginLeft: 10 }}>
                <span>
                  {t('settings.hubspot-salesforce.connected.features.ai-summary.title', { integrationName, defaultValue: 'Add AI Summaries and Transcripts to {{ integrationName }}' })}
                  <QuestionMarkIcon data-tooltip-id="tooltip-hubspot" style={{ display: 'inline-block', marginLeft: 5, color: 'var(--textSecondary)' }} />
                  <Tooltip id="tooltip-hubspot" place="bottom" >
                    <AddToIntegrationTooltip integrationName={integrationName} />
                  </Tooltip>
                </span>
                <br />
                <span style={{ display: 'inline-block', marginTop: 5 }}>
                  {t('settings.hubspot-salesforce.connected.features.ai-summary.description', { integrationName, defaultValue: 'Automatically add your call transcripts and AI summaries to {{ integrationName }} meeting associated with contact.' })}
                </span>
              </div>
            </div>

            <div className="hubspot-option" style={{
              background: isIntegrationActive ? 'var(--secondary100)' : 'var(--white)',
              borderRadius: 8,
              border: isIntegrationActive ? '1px solid var(--secondary600)' : 'var(--standardBorder)',
              opacity: isIntegrationActive ? '1' : '.6',
              display: 'flex',
              width: '40%',
              padding: 24,
              paddingLeft: 16,
              marginLeft: '5%',
              cursor: 'pointer'
            }} onClick={() => {
              if (!isGrowthOpportunitiesActive && !is_integration_active_google_calendar) {
                setShowHubspotCalendarRequiredPopup(true);
                return;
              }
              setGrowthOpportunitiesIsActive(!isGrowthOpportunitiesActive);
            }}>
              <div style={{ display: 'inline-flex', width: 30 }}>
                {isGrowthOpportunitiesActive ?
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1" y="1" width="28" height="28" rx="4" fill="none" stroke="var(--brand-primary)" strokeWidth="2" />
                    <path d="M20.798 10.236C21.4774 9.55356 22.5816 9.55093 23.2639 10.2305V10.2305C23.9464 10.9102 23.9483 12.0147 23.268 12.6968L14.0032 21.986C13.4437 22.547 12.5362 22.5508 11.9719 21.9946L6.7552 16.8526C6.06386 16.1712 6.06097 15.0567 6.74878 14.3717V14.3717C7.42642 13.6968 8.52087 13.6925 9.20372 14.3621L12.6185 17.7108C12.8252 17.9135 13.1571 17.911 13.3613 17.7059C16.2445 14.8097 18.1057 12.9402 20.798 10.236Z" fill="var(--brand-primary)" />
                  </svg>
                  :
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="29" height="29" rx="4.5" fill="none" stroke="#2C2C2C" />
                  </svg>
                }
              </div>
              <div style={{ display: 'inline-block', width: 'calc(100% - 40px)', marginLeft: 10 }}>
                <span>{t('settings.hubspot-salesforce.connected.features.growth.title', 'Get Growth Opportunities in Slack')}</span>
                <br />
                <span style={{ display: 'inline-block', marginTop: 5 }}>{t('settings.hubspot-salesforce.connected.features.growth.description', 'Get a notification in Slack every time Briefly finds a new Growth Opportunity after a call')}</span>
              </div>
            </div>
          </ FlexCenter10>
        </FlexColumn10 >
      ) : (
        userIsPaid && (<>
          <HR />
          <Row>
            <div style={{ opacity: isIntegrationConnected ? '1' : ".6" }}>
              <Integration>
                {t('settings.hubspot-salesforce.connected.features.ai-summary.title', { integrationName, defaultValue: 'Add AI Summaries and Transcripts to {{ integrationName }}' })}
                <Tooltip id={"tooltip-hubspot"} place="bottom" >
                  <AddToIntegrationTooltip integrationName={integrationName} />
                </Tooltip>
                <QuestionMarkIcon data-tooltip-id={"tooltip-hubspot"} />
              </Integration>
              <BodyLight>
                {t('settings.hubspot-salesforce.connected.features.ai-summary.description', { integrationName, defaultValue: 'Automatically add your call transcripts and AI summaries to {{ integrationName }} meeting associated with contact.' })}
              </BodyLight>
            </div>
            <Switch className="switch">
              <input disabled={isIntegrationConnected} type="checkbox" checked={isIntegrationActive} onClick={() => {

                if (isIntegrationActive && !is_integration_active_google_calendar) {
                  setShowHubspotCalendarRequiredPopup(true);
                  return;
                }

                setIntegrationIsActive(isIntegrationActive);

              }} />
              <span className="slider round"></span>

            </Switch>
          </Row>
        </>)
      )
      }
      {
        !userIsPaid && (
          <>
            <Row>
              <Integration >
                {t('settings.hubspot-salesforce.not-pro.title', 'Upgrade to a PRO account to use this feature')}
              </Integration>
              <ButtonLink medium to='/settings/subscriptions'>{t('settings.hubspot-salesforce.not-pro.upgrade', 'Upgrade to PRO')}</ButtonLink>
            </Row>
            <HR />

            <h6>📚 {t('settings.hubspot-salesforce.not-pro.bullet1.title', { integrationName, defaultValue: 'Add AI summaries and transcripts to {{ integrationName }}' })}</h6>
            <BodyLight>
              {t('settings.hubspot-salesforce.not-pro.bullet1.description', { integrationName, defaultValue: 'Briefly automatically adds AI-generated summaries and call transcripts directly to your {{ integrationName }} contacts. This ensures you have all the crucial information right where you need it.' })}
            </BodyLight>

            {isIntegrationVisible && (
              <>
                <h6>🎯 {t('settings.hubspot-salesforce.not-pro.bullet2.title', 'Get growth opportunities in Slack after each call')}</h6>
                <BodyLight>
                  {t('settings.hubspot-salesforce.not-pro.bullet2.description', 'Receive instant notifications in Slack whenever Briefly identifies new growth opportunities following a call. Stay ahead of the curve by being the first to know and act on these insights.')}
                </BodyLight>
              </>
            )}
          </>
        )
      }
    </FlexColumn10>
  </>;
};

export default ManageHubspotSalesforceMode;