import styled from "styled-components";

export const TextLinkButton = styled.button`
  background: transparent;
  border: none;
  color: var(--textSecondary);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  padding: 0;
  margin: 0;
  
  &:hover, &:focus {
    text-decoration: underline;
    color: var(--gray900);
  }

  &:focus {
    outline: none;
  }
`;