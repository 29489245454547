import { useState, useRef, useEffect } from 'react';
import { useOutletContext } from "react-router-dom";
import { getUserAccessToken, uploadTranscriptRequest, trackEvent } from '../utils/genericUtils';
import DatePicker, { registerLocale } from 'react-datepicker';
import { FileUploader } from "react-drag-drop-files";
import { Tooltip } from 'react-tooltip';
import { es, ptBR } from 'date-fns/locale';
import { ThreeDots } from 'react-loader-spinner';

import { useTranslation } from 'react-i18next';
import { CheckmarkCircleIcon, Page, Pages, TrashCanIcon } from './Icons';
import { Button } from '../theme';

const fileTypes = ["TXT", "VTT"];

registerLocale('es', es);
registerLocale('pt-BR', ptBR);

export default function UploadTranscript() {
  const [isLoading, setIsLoading] = useState(false);
  const [showCheck, setShowCheck] = useState(false);
  const { setShowZoomTranscriptInfoPopup, setShowMsTeamsTranscriptInfoPopup } = useOutletContext();

  const { t, i18n } = useTranslation();

  const [meetingName, setMeetingName] = useState("");

  const [startDate, setStartDate] = useState(new Date());

  const [isMeetingNameError, setIsMeetingNameError] = useState(false);

  const fileContainerRef = useRef();

  useEffect(() => {
    if (fileContainerRef.current) {
      fileContainerRef.current.querySelector('label:not(#ignore)').onclick = e => {
        if (!document.getElementById("open-file").matches(':hover')) {
          e.preventDefault();
          e.stopPropagation();
          if (document.getElementById("show-zoom-info").matches(':hover')) {
            setShowZoomTranscriptInfoPopup(true);
          }
          if (document.getElementById("show-ms-teams-info").matches(':hover')) {
            setShowMsTeamsTranscriptInfoPopup(true);
          }
        }
      };
    }
  }, fileContainerRef);

  const TAB_FILE = 'TAB_FILE';
  const TAB_TEXT = 'TAB_TEXT';

  const TAB_CONFIG = [
    {
      id: TAB_FILE,
      label: t('upload-transcript.meeting.tabs.file', 'Upload file')
    },
    {
      id: TAB_TEXT,
      label: t('upload-transcript.meeting.tabs.paste', 'Paste transcript')
    }
  ];

  const [file, setFile] = useState(null);
  const [pastedText, setPastedText] = useState('');

  const [currentTab, setCurrentTab] = useState(TAB_FILE);


  const handleChange = (file) => {
    setFile(file);
    setPastedText('');
  };

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  function uploadTranscriptContent(filecontent, meetingName) {
    getUserAccessToken(({ access }) => {
      uploadTranscriptRequest(filecontent, meetingName, startDate, access).then(response => {
        if (response.error) {
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setShowCheck(true);
          setTimeout(() => {
            window.location = '/meeting/bkend-' + response.transcriptId + '?transcript=1';
          }, 1000);
        }
      });
    });
  }


  function uploadTranscript() {
    setIsLoading(true);
    if (currentTab === TAB_TEXT) {
      trackEvent("briefly-upload-transcript-text", {
        text: pastedText,
        meetingName: meetingName,
        meetingStartDate: startDate
      });
      uploadTranscriptContent(pastedText, meetingName);
      return;
    }
    setIsLoading(true);
    var reader = new FileReader();

    reader.onload = function(evt) {
      if (evt.target.readyState != 2) return;
      if (evt.target.error) {
        alert('Error while reading file');
        return;
      }

      var filecontent = evt.target.result;

      setIsFormSubmitted(true);

      trackEvent("briefly-upload-transcript-file", {
        filecontent,
        meetingName: meetingName ? meetingName : file.name,
        meetingStartDate: startDate,
        filename: file.name
      });

      uploadTranscriptContent(filecontent, meetingName ? meetingName : file.name);
    };

    reader.readAsText(file);
  }

  const tabContentFile = <div ref={fileContainerRef}>
    <FileUploader handleChange={handleChange} name="file" types={fileTypes}>
      <div className={"upload-transcript-dropbox " + (file ? 'file-selected' : '')}>
        <Pages />

        <h5>
          {t('upload-transcript.meeting.upload.title', 'Drop your transcript file here or')}<Button disabled={file} inline id="open-file" >{t('upload-transcript.meeting.upload.button', 'Choose a file')}</Button>
        </h5>
        <p>
          {t('upload-transcript.meeting.upload.detail.part1', 'Only .txt and .vtt files allowed. For better results make sure the file is in the correct format')}{' '}
          <span id="show-zoom-info">{t('upload-transcript.meeting.upload.detail.part2', 'for Zoom')}</span>{' '}
          {t('upload-transcript.meeting.upload.detail.part3', 'and')}{' '}
          <span id="show-ms-teams-info">{t('upload-transcript.meeting.upload.detail.part4', 'for MS Teams')}</span>
        </p>

      </div>
    </FileUploader>

    {file ? <div className="selected-file">
      <div>
        <div>
          <Page />
        </div>
        <div className="file-name">
          {file.name}
        </div>
        <div className="delete-icon" onClick={() => setFile(null)}>
          <TrashCanIcon color="var(--brand-primary)" />
        </div>
      </div>
    </div> : ''}
  </div >;

  const tabContentText = <textarea placeholder="Paste your transcript here..." value={pastedText} onChange={e => { setPastedText(e.target.value); setFile(null); }} />;

  const submitIsEnabled = ((currentTab === TAB_FILE && file) || (currentTab === TAB_TEXT && pastedText.trim().length > 0));
  const anyTranscriptPasted = currentTab === TAB_FILE || (!isFormSubmitted && meetingName.trim().length > 0);

  const uploadHandler = anyTranscriptPasted ? uploadTranscript : () => setIsMeetingNameError(true);
  const handleKeyPress = ({ e, id }) => {
    if (e.keyCode === 13) setCurrentTab(id);
  };

  return <main id="upload-transcript-outer-container">
    <div id="upload-transcript-container">
      <h5>{t('upload-transcript.title', 'Upload any transcript to Briefly')}</h5>
      <div className="pink">
        <p>{t('upload-transcript.help.label', 'Do you need help exporting your transcript?')}</p>
        <div>
          <div tabIndex={0} onClick={() => {

            trackEvent("briefly-how-to-get-zoom-transcripts-click", {
              source: 'UPLOAD_TRANSCRIPT'
            });

            setShowZoomTranscriptInfoPopup(true);
          }}>
            {t('upload-transcript.help.zoom', 'How to get transcripts for Zoom')}
          </div>
          <div tabIndex={0} onClick={() => {

            trackEvent("briefly-how-to-get-ms-teams-transcripts-click", {
              source: 'UPLOAD_TRANSCRIPT'
            });

            setShowMsTeamsTranscriptInfoPopup(true);
          }}>
            {t('upload-transcript.help.teams', 'How to get transcripts for MS Teams')}
          </div>
        </div>
      </div>

      <input
        type="text"
        placeholder={t("upload-transcript.meeting.name-placeholder", "Add a name for your meeting")}
        value={meetingName}
        onChange={e => { setMeetingName(e.target.value); setIsMeetingNameError(false); }}
        style={isMeetingNameError ? { borderColor: 'red' } : {}} />

      <div className='extended-datepicker' style={{ marginTop: 20 }}>
        <DatePicker
          showIcon
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          locale={i18n.language}
          showTimeSelect
          timeFormat="p"
          timeIntervals={5}
          dateFormat="MMM d, yyyy h:mm aa"
        />
      </div>

      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <div className="upload-transcript-tabs">
          {TAB_CONFIG.map(({ id, label }) => {
            return (
              <div onKeyDown={(e) => handleKeyPress({ e, id })} tabIndex={0} key={id} className={id === currentTab ? 'active' : ''} onClick={() => setCurrentTab(id)}>
                {label}
              </div>
            );
          })}
        </div>
        <div className="upload-transcript-tab-content">
          {isLoading && <ThreeDots
            height="45"
            width="45"
            radius="10"
            color="var(--brand-primary)"
            ariaLabel="three-dots-loading"
            wrapperStyle={{ alignItems: "center", justifyContent: "center" }}
            visible={true}
          />}
          {showCheck && (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: 'column' }}>
              <CheckmarkCircleIcon color="var(--brand-secondary)" size={56} />
              <ThreeDots
                height="25"
                width="25"
                radius="6"
                color="var(--brand-primary)"
                ariaLabel="three-dots-loading"
                wrapperStyle={{ alignItems: "center", justifyContent: "center" }}
                visible={true}
              />
            </div>
          )}
          {!isLoading && !showCheck && (
            currentTab === TAB_FILE ? tabContentFile : tabContentText
          )}
        </div>
      </div>
      <Button
        style={{ margin: "auto" }}
        disabled={isLoading || showCheck || !submitIsEnabled}
        data-tooltip-id="tooltip-button"
        data-tooltip-content="Add a name for your meeting"
        data-tooltip-place="top"
        onClick={() => {
          uploadHandler();
        }}>{t('upload-transcript.meeting.upload-button', 'Upload transcript')}</Button>

      {!anyTranscriptPasted && <Tooltip id="tooltip-button" openOnClick={true} />}

    </div>
  </main >;
}
