import styled from "styled-components";
import Alert from "../../Reusable/Alert"
import { useTranslation } from "react-i18next";

interface PromptPreviewModalProps {
  promptName?: string;
  promptContent?: string;
  onOpenChange: (state: boolean) => void;
}

const WrappedParagraph = styled.p`
  margin: 2rem 1rem;
  background-color: var(--backgroundSecondary);
  padding: 0.5rem 1rem;
  border: solid 1px var(--gray500);
  border-radius: var(--standardRadius);
  height: 6rem;
  overflow-y: auto;
  color: var(--textSecondary);
  user-select: none;
`;

export const PromptPreviewModal = (props: PromptPreviewModalProps) => {
  const { promptName, onOpenChange, promptContent } = props;
  const { t } = useTranslation();
  const isOpen = Boolean(promptName && promptContent);
  
  return (
    <Alert
      open={isOpen}
      title={promptName as string}
      cancelContent={t('close', { ns: 'Actions', defaultValue: 'Close' })}
      onOpenChange={onOpenChange}
      onConfirm={() => onOpenChange(false)}>
      <WrappedParagraph>{promptContent}</WrappedParagraph>
    </Alert>
  )
}